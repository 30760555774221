import React, { Component } from 'react';
import PropTypes from 'prop-types';
import store from '@/store';
import { connect } from 'react-redux';
import { toggleMenu, closeMenu } from '@/actions';
import './SlidingMenu.scss';
import { Icon } from '@reservamos/elements';
import { CurrentLang } from 'utils/lang';
import { withTranslation } from 'react-i18next';
import SlidingMenuItem from '../atoms/SlidingMenuItem';
import LoyaltyButton from '../../components/LoyaltyButton';
import LngButton from '../atoms/LngButton';
import LoyaltyProgramsContext from '../../loyalty/context/LoyaltyProgramsContext';

class SlidingMenu extends Component {
  constructor(props) {
    super(props);
    this.store = store;
    this.wrapperRef = React.createRef();
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.switchMenu = this.switchMenu.bind(this);
    this.onClickOutside = this.onClickOutside.bind(this);
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  handleClickOutside(event) {
    const { isOpen } = this.props;
    if (isOpen && this.wrapperRef && !this.wrapperRef.current.contains(event.target)) {
      this.onClickOutside();
    }
  }

  onClickOutside() {
    this.store.dispatch(closeMenu());
  }

  switchMenu() {
    this.store.dispatch(toggleMenu());
  }

  render() {
    const {
      isOpen,
      copies: { menu: copies },
      features,
    } = this.props;
    const { costaPass: { config: costaPassConfig } = {} } = this.context;
    const lang = CurrentLang();

    const menuItems = copies.menuUrls[lang].map((urls) => {
      if (urls.submenu) {
        return (
          <SlidingMenuItem key={urls.label} label={urls.label}>
            {urls.submenu.map((menuUrls) => {
              return (
                <SlidingMenuItem key={menuUrls.label} label={menuUrls.label} href={menuUrls.link} />
              );
            })}
          </SlidingMenuItem>
        );
      }

      return <SlidingMenuItem key={urls.label} label={urls.label} href={urls.link} />;
    });

    return (
      <div
        ref={this.wrapperRef}
        className={`rvui-sliding-menu ${isOpen ? 'rvui-sliding-menu-open' : ''}`}
      >
        <div className="rvui-sliding-menu-wrapper">
          {features.DOTERS_ENABLED && (
            <LoyaltyButton id="siempre-plus-widget-header" isLink visible />
          )}
          {features.COSTAPASS_ENABLED && (
            <LoyaltyButton
              id="siempre-plus-widget-desktop-header-costapass"
              isLink
              extraConfig={costaPassConfig}
            />
          )}
          <LngButton />
        </div>
        <button
          className="rvui-sliding-close"
          onClick={this.switchMenu}
          aria-label="Close sliding menu"
        >
          <Icon type="Close" size="S" />
        </button>
        <div className="rvui-sliding-menu-list">{menuItems}</div>
      </div>
    );
  }
}

SlidingMenu.propTypes = {
  isOpen: PropTypes.bool,
  copies: PropTypes.object.isRequired,
  features: PropTypes.object.isRequired,
};

SlidingMenu.defaultProps = {
  isOpen: false,
};

SlidingMenu.contextType = LoyaltyProgramsContext;

const mapStateToProps = (state) => ({
  copies: state.whitelabelConfig.copies,
  features: state.whitelabelConfig.features,
});

export default connect(mapStateToProps)(withTranslation()(SlidingMenu));
