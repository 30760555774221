import moment from 'moment';

const separationDays = (date1, date2) => {
  const date1moment = moment.isMoment(date1) ? date1 : moment(date1, 'DD-MM-YYYY');
  const date2moment = moment.isMoment(date2) ? date2 : moment(date2, 'DD-MM-YYYY');

  return date1moment.startOf('day').diff(date2moment.startOf('day'), 'days');
};

const getPricingProp = (way, isRoundTrip) => {
  let pricingProp = 'pricing';

  if (isRoundTrip) {
    pricingProp = way === 'departure' ? 'departureRoundTripPricing' : 'roundTripPricing';
  }

  return pricingProp;
};

export const getCurrentPrice = (prices, way, isRoundTrip) => {
  const minPrice = 0;
  if (!prices) return minPrice;

  const pricingProp = getPricingProp(way, isRoundTrip);
  if (!prices[pricingProp]) return minPrice;

  return prices[pricingProp];
};

const departureValidations = (
  isRoundTrip,
  prevDayDate,
  nextDayDate,
  prevDayPrice,
  nextDayPrice,
  returnDate,
) => {
  let prevDay = false;
  let nextDay = false;

  const today = moment();

  if (isRoundTrip) {
    if (separationDays(returnDate, nextDayDate) < 0) nextDay = true;
  }

  if (separationDays(prevDayDate, today) < 0) prevDay = true;

  return { prevDay, nextDay };
};

const returnValidations = (isRoundTrip, prevDayDate, prevDayPrice, nextDayPrice, departureDate) => {
  let prevDay = false;
  const nextDay = false;

  const today = moment();

  if (isRoundTrip) {
    if (separationDays(prevDayDate, departureDate) < 0) prevDay = true;
  }

  if (separationDays(prevDayDate, today) < 0) prevDay = true;

  return { prevDay, nextDay };
};

export const getDisabledButtons = (
  way,
  isRoundTrip,
  prevDayDate,
  nextDayDate,
  prevDayPrice,
  nextDayPrice,
  departureDate,
  returnDate,
) => {
  return way === 'departure'
    ? departureValidations(
        isRoundTrip,
        prevDayDate,
        nextDayDate,
        prevDayPrice,
        nextDayPrice,
        returnDate,
      )
    : returnValidations(isRoundTrip, prevDayDate, prevDayPrice, nextDayPrice, departureDate);
};
