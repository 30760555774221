import React from 'react';
import PropTypes from 'prop-types';
import { Waypoint } from 'react-waypoint';
import { Spacing } from '@reservamos/elements';
import '../styles/components/InfiniteScroll';

const defaultProps = {
  show: 10,
};

const propTypes = {
  items: PropTypes.array,
  show: PropTypes.number,
  isHorizontal: PropTypes.bool,
  usesSideBar: PropTypes.bool,
};

class InfiniteScroll extends React.Component {
  constructor(props) {
    super(props);
    this.state = { currentlyShowing: props.show };

    this.resetShowing = this.resetShowing.bind(this);
    this.showMoreElements = this.showMoreElements.bind(this);
  }

  resetShowing() {
    const { show } = this.props;
    this.setState({ currentlyShowing: show });
  }

  showMoreElements() {
    const { currentlyShowing } = this.state;
    const { show } = this.props;

    this.setState({
      currentlyShowing: currentlyShowing + show,
    });
  }

  render() {
    const { items, isHorizontal, usesSideBar } = this.props;
    const { currentlyShowing } = this.state;
    const showingItems = items.slice(0, currentlyShowing);
    let lastResult = null;

    if (items.length > currentlyShowing) {
      lastResult = <Waypoint onEnter={this.showMoreElements} />;
    }

    let content = showingItems;

    if (showingItems?.length > 2 && usesSideBar && isHorizontal) {
      content = (
        <div className="horizontal-scroll">
          <Spacing size="S">{showingItems}</Spacing>
        </div>
      );
    }

    return (
      <div>
        <Waypoint onEnter={this.resetShowing} />
        <Spacing size="S" vertical={!isHorizontal} flexGrow>
          {content}
        </Spacing>
        {lastResult}
      </div>
    );
  }
}

InfiniteScroll.propTypes = propTypes;
InfiniteScroll.defaultProps = defaultProps;

export default InfiniteScroll;
