import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import hexRgb from 'hex-rgb';

const propTypes = {
  children: PropTypes.node.isRequired,
  theme: PropTypes.object.isRequired,
  funnelStyle: PropTypes.string.isRequired,
  funnelStyleBrandVariation: PropTypes.string,
};

const CSSVariablesProvider = ({
  children,
  theme,
  funnelStyle = 'CLASSIC',
  funnelStyleBrandVariation = '',
}) => {
  const { colors, logos } = theme;

  useEffect(() => {
    const htmlElement = document.documentElement;
    htmlElement.setAttribute('data-funnel-style', funnelStyle);
    htmlElement.setAttribute('data-funnel-style-brand-variant', funnelStyleBrandVariation);
  }, [funnelStyle, funnelStyleBrandVariation]);

  // Some colors cannot be transformed to RGB, these are the exceptions because their values are not hex.
  // This colors don't need to be transformed to RGB.
  const colorsKeysToNotTransform = ['headerText'];
  const rgbColors = {};
  Object.keys(colors).forEach((key) => {
    if (colorsKeysToNotTransform.includes(key)) return;
    const transformedKey = key.replace(/([A-Z])/g, (g) => `-${g[0].toLowerCase()}`);
    rgbColors[`--RGB_${transformedKey}`] = hexRgb(colors[key], { format: 'array' })
      .slice(0, 3)
      .join(',');
  });

  return (
    <div
      style={{
        '--primary': colors.primary,
        '--background-header': colors.backgroundHeader || colors.primary,
        '--primary-strong': colors.primaryStrong,
        '--accent': colors.accent,
        '--accent-strong': colors.accentStrong,
        '--color-success': colors.success,
        '--color-success-strong': colors.successStrong,
        '--color-warning': colors.warning,
        '--color-warning-strong': colors.warningStrong,
        '--color-error': colors.error,
        '--color-error-strong': colors.errorStrong,
        '--color-info': colors.info,
        '--color-info-strong': colors.infoStrong,
        '--color-discount': colors.discount,
        '--color-yellow': colors.yellow,
        '--src-topbar-logo': `url(${logos.topbarLogoUrl})`,
        '--src-loading-1': `url(${logos.loading1})`,
        '--src-loading-2': `url(${logos.loading2})`,
        '--border-seats-selected': colors.borderSeatsSelected || colors.accentStrong,
        '--seats-selected': colors.seatsSelected || colors.accent,
        '--purchase-footer-bg': colors.footerBackground || colors.primary,
        '--seats-occupied': colors.seatsOccupied,
        '--border-seats-occupied': colors.borderSeatsOccupied,
        '--bg-searchform-wrapper': colors.bgSearchFormWrapper || colors.primary,
        '--loyalty': colors.loyalty,
        '--loyalty-light': colors.loyaltyLight,
        '--loyalty-session': colors.loyaltySession,
        '--main-button': colors.mainButton || colors.primary,
        '--main-button-strong': colors.mainButtonStrong || colors.primaryStrong,
        ...rgbColors,
      }}
    >
      {children}
    </div>
  );
};

CSSVariablesProvider.propTypes = propTypes;

export default CSSVariablesProvider;
