import React, { useState, useEffect, useMemo } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import getPurchaseStepName from 'utils/purchase/getPurchaseStepName';
import { Text } from '@reservamos/elements';
import BreadcrumbItem from '../../../ui/atoms/BreadcrumbItem';
import useWhitelabelTheme from '../../../hooks/whitelabel/useWhitelabelTheme';
import 'styles/components/purchase/PurchaseBreadcrumbs';

const propTypes = {
  roundTrip: PropTypes.bool.isRequired,
  token: PropTypes.string.isRequired,
  allowsSeatSelection: PropTypes.bool.isRequired,
  funnelStepCount: PropTypes.number.isRequired,
  hasSelectedSeats: PropTypes.bool.isRequired,
  hasPassengers: PropTypes.bool.isRequired,
  setNotification: PropTypes.func.isRequired,
  finishSeatSelection: PropTypes.func.isRequired,
  resetPassengers: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

const PurchaseBreadcrumbs = ({
  roundTrip,
  token,
  allowsSeatSelection,
  hasSelectedSeats,
  hasPassengers,
  setNotification,
  finishSeatSelection,
  resetPassengers,
  funnelStepCount,
  t,
}) => {
  const location = useLocation();
  const history = useHistory();
  const { purchaseStepsIcons } = useWhitelabelTheme();

  const [activeStepName, setActiveStepName] = useState('');
  const [activeStepNumber, setActiveStepNumber] = useState(0);
  const [noSeatsOffset, setNoSeatsOffset] = useState(0);
  const [seatsWayLabel, setSeatsWayLabel] = useState('');

  useEffect(() => {
    setNoSeatsOffset(allowsSeatSelection ? 0 : 1);
  }, [allowsSeatSelection]);

  const simpleStepNames = useMemo(() => {
    return {
      dSeats: 'seats',
      rSeats: 'seats',
      passengers: 'passengers',
      checkout: 'checkout',
    };
  }, []);

  const stepNumbers = useMemo(() => {
    return {
      dSeats: 1,
      rSeats: 1,
      passengers: 2,
      checkout: 3,
    };
  }, []);

  useEffect(() => {
    setSeatsWayLabel(roundTrip && location.pathname.includes('seats/return') ? 'back' : 'outward');
    setActiveStepName(getPurchaseStepName(location.pathname));
    setActiveStepNumber(stepNumbers[getPurchaseStepName(location.pathname)] - noSeatsOffset);
  }, [location, stepNumbers, noSeatsOffset, roundTrip]);

  const handleSeatsClick = () => {
    history.push(`/purchase/${token}/seats/departure`);
  };

  const handlePassengersClick = () => {
    history.push(`/purchase/${token}/passengers`);
  };

  const handleCheckoutClick = () => {
    history.push(`/purchase/${token}/checkout`);
  };

  const handleStepClick = (stepName, stepNumber) => {
    const isLatterStep = stepNumber > activeStepNumber;
    if (activeStepName === stepName) return;

    if (
      (activeStepName === 'checkout' && stepName === 'seats') ||
      (activeStepName === 'passengers' && stepName === 'seats')
    ) {
      resetPassengers();
    }

    switch (stepName) {
      case 'seats':
        if (!allowsSeatSelection) return;
        handleSeatsClick();
        break;

      case 'passengers':
        if (isLatterStep && !hasSelectedSeats) {
          return setNotification('you_need_to_select_seats');
        }

        if (activeStepName !== 'checkout') {
          finishSeatSelection(history);
        }

        handlePassengersClick();
        break;

      case 'checkout':
        if (isLatterStep && !hasPassengers) {
          setNotification('you_need_to_register_passengers');
          return;
        }

        handleCheckoutClick();
        break;

      default:
        break;
    }
  };

  return (
    <div className="breadcrumbs">
      <div className="breadcrumbs-wrap">
        {allowsSeatSelection && (
          <BreadcrumbItem
            title={t('purchase:tab.seats')}
            icon={purchaseStepsIcons.seats || 'Seats'} //  Ask if the brand has a custom icon, else we do the fallback (current icon)
            onClick={() => handleStepClick('seats', 1)}
            label={seatsWayLabel || undefined}
            isActive={simpleStepNames[activeStepName] === 'seats'}
          />
        )}

        <BreadcrumbItem
          title={t('purchase:tab.passengers')}
          icon={purchaseStepsIcons.passengers || 'Passengers'} //  Ask if the brand has a custom icon and pass the url, else we do the fallback (current icon)
          onClick={() => handleStepClick('passengers', 2)}
          isActive={simpleStepNames[activeStepName] === 'passengers'}
        />

        <BreadcrumbItem
          title={t('purchase:tab.payment')}
          icon={purchaseStepsIcons.checkout || 'Payment'} //  Ask if the brand has a custom icon, else we do the fallback (current icon)
          onClick={() => handleStepClick('checkout', 3)}
          isActive={simpleStepNames[activeStepName] === 'checkout'}
        />
      </div>

      <Text size="S" weight="semibold">
        {t('purchase:tab.step', { step: activeStepNumber, steps: funnelStepCount })}
      </Text>
    </div>
  );
};

PurchaseBreadcrumbs.propTypes = propTypes;

export default PurchaseBreadcrumbs;
