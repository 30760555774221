import moment from 'moment';
import store from '@/store';
import { encodePassengers } from 'utils/Reserbus';

export default function returnTripsUrlFromDeparture(departureTrip, isOpenTicket, passengers) {
  const DATE_FORMAT = 'DD-MMM-YY';
  const departureMoment = isOpenTicket ? moment() : moment(departureTrip.get('departure'));
  const returnMoment = departureMoment.clone().add(1, 'day');
  const departureDate = departureMoment.format(DATE_FORMAT);
  const returnDate = returnMoment.format(DATE_FORMAT);
  const encodedPassengersSelection = (passengers && encodePassengers(passengers)) || 'A1';

  const fragments = departureTrip.get('fragments').toJS();
  const {
    trip: { lineId },
  } = fragments[0];

  const route = [
    '/search',
    departureTrip.getIn(['origin', 'id']),
    departureTrip.getIn(['destination', 'id']),
    departureDate,
    returnDate,
    'p',
    encodedPassengersSelection,
    departureTrip.get('tripSlug'),
    'returns',
  ];

  const { PROVIDERS_SELECTION_ENABLED } = store.getState().whitelabelConfig.features;
  if (!PROVIDERS_SELECTION_ENABLED) route.push(lineId);

  return route.join('/');
}
