import React from 'react';
import PropTypes from 'prop-types';
import { Link } from '@reservamos/elements';
import { useTranslation } from 'react-i18next';
import useWhitelabelFeatures from 'hooks/whitelabel/useWhitelabelFeatures';

const propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  time: PropTypes.string.isRequired,
  brand: PropTypes.string,
  destinationDetails: PropTypes.object,
};

const EndItem = ({ title, description, time, brand, destinationDetails }) => {
  const { t } = useTranslation('trips');
  const hasCoordinates =
    destinationDetails.coordinates?.lat || destinationDetails.coordinates?.long;
  const features = useWhitelabelFeatures();
  const showTerminal = features.SHOW_TERMINAL_NAME_AT_ITINERARY_ITEM;
  return (
    <div className="route-detail-row departure">
      <div className="rd-left">
        <p>
          <b>{time}</b>
        </p>
      </div>

      <div className="rd-center">
        <i className="ic-dot" />
      </div>

      <div className={`rd-right ic-dot ${brand}`}>
        <p>
          <b>{title}</b>
          {showTerminal && <span>{description}</span>}
          {destinationDetails.address && <p>{destinationDetails.address}</p>}
        </p>
        {hasCoordinates ? (
          <Link
            newTab
            type="primary"
            size="XS"
            href={`https://www.google.com/maps/search/?api=1&query=${destinationDetails.coordinates.lat},${destinationDetails.coordinates.long}`}
            text={t('see_location')}
          />
        ) : null}
      </div>
    </div>
  );
};

EndItem.propTypes = propTypes;

export default EndItem;
