/* eslint-disable react/jsx-props-no-spreading */
import React, { useCallback, useEffect, useState, useRef } from 'react';
import { Map, List } from 'immutable';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { RECEIVE_BUSES } from 'constants/ActionTypes';
import { Icon, Spacing, Text } from '@reservamos/elements';
import TripItem from 'components/search/TripItem';
import { useTranslation } from 'react-i18next';
import camelizeConverter from 'utils/camelizeConverter';
import { search as searchApi } from 'js-api-client';
import { camelizeKeys } from 'humps';
import { setCities, setTerminals, setLines } from '@/actions/catalogs';
import store from '@/store';
import parseTrip from 'models/parseTrip';
import { useHistory } from 'react-router-dom';
import { getSearchDateParamFromMoment } from 'utils/search/searchDateParam';
import setConfig from '../../../actions/config';
import OpenTicketWrapper from '../../search/OpenTicketWrapper';
import OpenTicketButton from '../../search/OpenTicketButton';
import LabelLoading from '../../../ui/molecules/LabelLoading';
import { filterTrips } from '../../../utils/tripFilters';
import {
  setDynamicPriceAttributes,
  setProviders,
  setTransportState,
} from '../../../actions/providers';
import { receiveSearch, setupTrips, receiveTrips } from '../../../actions/search';

const ExchangeTripList = ({
  originSlug,
  destinationSlug,
  date,
  onSelectTrip,
  newPurchase,
  operationNumbers,
  nit,
  openTicket: isOpenTicket,
  setIsLoading,
  paymentPlans,
  installmentsMinAmount,
  tripFilters,
  walletOriginalType,
}) => {
  const { features } = useSelector((state) => state.whitelabelConfig);
  const { t: tExchange } = useTranslation('exchange');
  const { t: tSearch } = useTranslation('search');
  const history = useHistory();

  const [isPolling, setIsPolling] = useState(true);
  const [searchDetails, setSearchDetails] = useState(undefined);
  const [tripList, setTripList] = useState([]);
  const searchId = useRef(null);
  const dispatch = useDispatch();

  /**
   * Process the search polling to get the trips from the search
   * @param {*} response - search response
   */
  const onReceiveBuses = (response) => {
    const { status, payload } = response;
    searchId.current = payload && payload.id;
    setIsLoading(false);
    setIsPolling(true);
    if (payload && status !== 'aborted') {
      const searchResult = camelizeKeys(payload, camelizeConverter);
      const { terminals, lines } = searchResult;
      const { cities } = store.getState();
      const { trips, state } = searchResult;
      const tripCount = searchResult.trips.length;

      const tripsFiltered = trips.filter((trip) => !false || trip.isBuyable);
      tripsFiltered.forEach((trip) => parseTrip({ trip, cities, terminals, lines }));

      // If the original purchase has a costapass  wallet type, the trips should be filtered by that wallet type
      // if (walletOriginalType === 'costapass')
      //   tripsFiltered = tripsFiltered.filter((trip) => trip.availableWallets.includes('costapass'));

      dispatch(setTerminals(terminals));
      dispatch(setLines(lines));
      dispatch(setProviders(searchResult.id, tripsFiltered, false));

      setIsLoading(false);
      setSearchDetails(searchResult);

      /**
       * If needed to filter the trips that supports costapass,
       * this conditon has to be added in the next if: && walletOriginalType !== 'costapass'
       */
      if (tripsFiltered.length) {
        setTripList(tripsFiltered);
        dispatch(receiveTrips(RECEIVE_BUSES, searchResult));
      }
      if (state === 'finished' || status === 'retries_exceeded' || status === 'finished') {
        // overwrite search state since polling remained pending after max attempts
        setTripList(tripsFiltered);
        searchResult.state = 'finished';
        dispatch(receiveTrips(RECEIVE_BUSES, searchResult));
        setIsPolling(false);
        if (status === 'retries_exceeded') throw new Error('Polling retries exceeded');
      } else {
        dispatch(setTransportState('bus', searchResult.id, searchResult.state, tripCount));
      }
    }
  };

  const getSearchData = useCallback(() => {
    if (originSlug !== '' && destinationSlug !== '' && date !== '') {
      setTripList([]);
      if (searchId.current) searchApi.stopSearch(searchId.current);
      const payload = {
        date,
        origin: originSlug,
        destination: destinationSlug,
        passengers: ['adult'],
        way: 'departure',
        round: false,
      };

      const createSearchParams = {
        onReceiveBuses,
        initFlights: false,
        initRides: false,
      };
      setIsLoading(true);
      searchApi.create(payload, createSearchParams).then((response) => {
        const { cities, lines, config, search } = response;
        const { id } = search;
        const { bus } = search.type_of_transport;
        dispatch(setDynamicPriceAttributes(search));
        dispatch(setConfig(config));
        dispatch(setCities(cities));
        dispatch(receiveSearch('departure', search, lines));
        dispatch(setupTrips(search));
        dispatch(setTransportState('bus', id, bus.active ? 'pending' : 'disabled'));
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [originSlug, destinationSlug, date, setIsLoading, dispatch]);

  useEffect(() => {
    if ((originSlug === '' || destinationSlug === '' || date === '') && tripList.length > 0) {
      setTripList([]);
      setSearchDetails(null);
    }
  }, [date, destinationSlug, originSlug, tripList.length]);

  useEffect(() => {
    getSearchData();
  }, [getSearchData]);

  /**
   * Handle the selection of a trip.
   *
   * @param {string} originSlug - The origin location identifier.
   * @param {number} seenPrice - The price of the trip seen by the user.
   *
   * @fires newPurchase - Makes a new purchase with the given parameters.
   * @fires onSelectTrip - Triggers the selection of a trip.
   */
  const handleTripSelect = (originSlug, seenPrice) => {
    newPurchase(originSlug, seenPrice, { operationNumbers, nit });
    onSelectTrip(true);
  };

  const tripsToRender = tripList.filter(
    (trip) => trip.openTicket === false && trip.redirectTo === null,
  );

  if (searchDetails && isPolling === false && tripsToRender.length === 0) {
    const dateMoment = moment(date, 'DD-MM-YYYY');
    const formattedDate = tSearch('date_human_sentence', {
      weekDay: dateMoment.format('dddd'),
      day: dateMoment.format('D'),
      month: dateMoment.format('MMMM'),
      year: dateMoment.year(),
    });
    const notTripsMessage =
      walletOriginalType !== 'costapass'
        ? `${tSearch('schedules_not_found')}: ${formattedDate}`
        : tSearch('schedules_not_found_costapass');
    return (
      <div className="no-results-container">
        <div className="no-results-wrapper">
          <Spacing size="XL" vertical alignItems="flex-start">
            <Spacing size="XL" vertical>
              <Spacing alignItems="center">
                <Icon type="Calendar" size="L" />
                <Text size="L" weight="bold">
                  {notTripsMessage}
                </Text>
              </Spacing>
            </Spacing>
          </Spacing>
        </div>
      </div>
    );
  }

  const immutableTrips = List(tripsToRender.map((trip) => Map(trip)));
  const tripsFiltered = filterTrips(immutableTrips, null, '', tripFilters.get('filters'));
  const openTrip = tripList.find((trip) => trip.openTicket === true && trip.redirectTo === null);
  const showTrips = searchDetails && tripsToRender.length > 0;
  const showOpenTicket =
    !isOpenTicket && features.CAN_EXCHANGE_OPEN_TICKET && openTrip && showTrips;

  const selectOpenTripList = () => {
    const origin = openTrip?.originId;
    const destination = openTrip?.destinationId;
    const departureDate = getSearchDateParamFromMoment(moment(searchDetails?.departs));
    const route = `/search/${origin}/${destination}/${departureDate}/p/A1/departures/open?isExchange=true`;
    history.push(route);
  };

  const handleOnOpenTripSelected = () => {
    return features.OPEN_TICKET_PROVIDER_SELECTION_ENABLED
      ? selectOpenTripList()
      : handleTripSelect(openTrip.id, openTrip.pricing.total);
  };

  return (
    <>
      {searchDetails && (
        <Spacing vertical size="L">
          <Text weight="semibold">{tExchange('labels.select_schedule')}</Text>
          <Spacing vertical size="S">
            {isPolling && <LabelLoading />}
            {showOpenTicket && (
              <Spacing justifyContent="flex-end">
                <OpenTicketWrapper>
                  <OpenTicketButton
                    trip={openTrip}
                    selectTrip={handleOnOpenTripSelected}
                    isExchange
                  />
                </OpenTicketWrapper>
              </Spacing>
            )}
            {showTrips &&
              tripsFiltered.toJS().map((trip, index) => {
                const origin = searchDetails.terminals[trip.originId];
                const destination = searchDetails.terminals[trip.destinationId];

                const hasDiscountType = Boolean(trip.pricing.discountType);

                return (
                  <>
                    <TripItem
                      key={trip.id}
                      trip={trip}
                      onSelectClick={() => handleTripSelect(trip.id, trip.pricing.total)}
                      selectTrip={() => handleTripSelect(trip.id, trip.pricing.total)}
                      arrival={trip.arrival}
                      availability={trip.availability}
                      departure={trip.departure}
                      destination={destination}
                      duration={trip.duration}
                      id={trip.id}
                      isRoundTrip={false}
                      origin={origin}
                      providerDiscount={trip.pricing.providerDiscount}
                      providerDiscounts={trip.pricing.providerDiscounts}
                      total={trip.pricing.total}
                      totalBeforeDiscount={trip.pricing.totalBeforeDiscount}
                      way="departure"
                      supportWoman={trip.supportWoman}
                      availableCategories={trip.passengerTypes}
                      hasTransit={trip.hasTransit}
                      stopoverPlace={trip.stopoverPlace}
                      capacity={trip.capacity}
                      type={trip.type || trip.transportType}
                      serviceType={trip.transportType === 'bus' ? trip.line.serviceType : null}
                      hasDiscountType={hasDiscountType}
                      hasRouteDetails={Boolean(trip.hasRouteDetails)}
                      position={index + 1}
                      paymentPlans={paymentPlans}
                      installmentsMinAmount={installmentsMinAmount}
                      variableDepartureTime={trip.variableDepartureTime || false}
                      isExchange
                      pricing={trip.pricing}
                    />
                  </>
                );
              })}
          </Spacing>
        </Spacing>
      )}
    </>
  );
};

ExchangeTripList.propTypes = {
  originSlug: PropTypes.string.isRequired,
  destinationSlug: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  onSelectTrip: PropTypes.func.isRequired,
  walletOriginalType: PropTypes.string,
  newPurchase: PropTypes.func.isRequired,
  operationNumbers: PropTypes.array.isRequired,
  nit: PropTypes.string,
  setIsLoading: PropTypes.func.isRequired,
  paymentPlans: PropTypes.any,
  installmentsMinAmount: PropTypes.number,
  openTicket: PropTypes.bool,
  tripFilters: PropTypes.object,
};

export default ExchangeTripList;
