import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Text, Currency, Gap, Icon } from '@reservamos/elements';

const DiscountRowItem = ({ label, availability, total }) => {
  const { t } = useTranslation('general');
  const noAvailable = availability === 0;

  return (
    <div className="provider-info-discounts-item">
      <Gap size="XS">
        <Icon type="Amenity" size="S" color={noAvailable ? 'grayLight' : 'accent'} />
        <Text size="S">{label}</Text>
      </Gap>
      <Text size="S">{t('search:seat', { count: availability })}</Text>
      {noAvailable ? (
        <Text size="S" italic>
          {t('text.not_available')}
        </Text>
      ) : (
        <Currency color="discount" price={total} decimals={1} weight="semibold" />
      )}
    </div>
  );
};

DiscountRowItem.propTypes = {
  label: PropTypes.string.isRequired,
  availability: PropTypes.number.isRequired,
  total: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
};

export default DiscountRowItem;
