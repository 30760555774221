import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Tooltip, OutlineLink, FlatButton, Spacing } from '@reservamos/elements';
import { useTranslation } from 'react-i18next';
import './PointsSlider/PointsSlider.scss';

/** Button to add points to the wallet within a external url */
function AddPointsButton({ rechargeURL, tooltipPosition = 'right', handleUpdateWallet }) {
  const { t: tPayment } = useTranslation('payment');
  const [showReloadButton, setReloadButton] = useState(false);

  /** Add money handler */
  const addClick = () => {
    setReloadButton(true);
  };

  /** Update wallet handler */
  const updateClick = () => {
    setReloadButton(false);
    handleUpdateWallet();
  };

  return (
    <Spacing>
      {showReloadButton && (
        <FlatButton onClick={updateClick} size="S">
          {tPayment('button.reload_wallet_balance')}
        </FlatButton>
      )}
      <Tooltip text={tPayment('label.add_balance_costapass_wallet')} horizontal={tooltipPosition}>
        <OutlineLink
          iconType="PlusSign"
          mobileSize="S"
          variant="primary"
          href={rechargeURL}
          newTab
          padding="M"
          onClick={addClick}
        >
          {tPayment('label.add_balance')}
        </OutlineLink>
      </Tooltip>
    </Spacing>
  );
}

AddPointsButton.propTypes = {
  rechargeURL: PropTypes.string,
  tooltipPosition: PropTypes.string,
  handleUpdateWallet: PropTypes.func,
};

export default AddPointsButton;
