import { Icon } from '@reservamos/elements';
import React from 'react';
import './ArrowRight.scss';

const ArrowRight = () => (
  <div className="arrow-right">
    <div className="arrow-right-container">
      <span>
        <Icon type="ChevronRight" size="S" color="white" />
      </span>
      <span>
        <Icon type="ChevronRight" size="S" color="white" />
      </span>
      <span>
        <Icon type="ChevronRight" size="S" color="white" />
      </span>
    </div>
  </div>
);

export default ArrowRight;
