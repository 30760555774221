import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import useScript from '../../hooks/useScript';

const propTypes = {
  children: PropTypes.node.isRequired,
  config: PropTypes.object.isRequired,
};

const openPayScripts = {
  mexico: {
    openPayJs: 'https://js.openpay.mx/openpay.v1.min.js',
    antiFraud: 'https://js.openpay.mx/openpay-data.v1.min.js',
  },
  colombia: {
    openPayJs: 'https://resources.openpay.co/openpay.v1.min.js',
    antiFraud: 'https://resources.openpay.co/openpay-data.v1.min.js',
  },
};

const OpenPayLoader = ({ children, config }) => {
  const { enabled, id, apiKey, isSandbox, openPayRegion } = config;
  const { openPayJs, antiFraud } = openPayScripts[openPayRegion] || openPayScripts.mexico;
  const { isLoaded: openPayJsScriptIsLoaded } = useScript(openPayJs);
  const { isLoaded: antiFraudScriptIsLoaded } = useScript(antiFraud, {
    shouldLoadScript: openPayJsScriptIsLoaded,
  });

  useEffect(() => {
    if (
      enabled &&
      id &&
      apiKey &&
      openPayJsScriptIsLoaded &&
      antiFraudScriptIsLoaded &&
      window.OpenPay
    ) {
      window.OpenPay.setId(id);
      window.OpenPay.setApiKey(apiKey);
      window.OpenPay.setSandboxMode(isSandbox);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openPayJsScriptIsLoaded, antiFraudScriptIsLoaded]);

  return <>{children}</>;
};

OpenPayLoader.propTypes = propTypes;

export default OpenPayLoader;
