import React from 'react';
import PropTypes from 'prop-types';
import { Icon, Link, Text } from '@reservamos/elements';
import { useTranslation } from 'react-i18next';
import useWhitelabelFeatures from 'hooks/whitelabel/useWhitelabelFeatures';
import dataFinder from '@/store/dataFinder';

/**
 * Converts transport type name.
 * @param {string} transportType - current trip transport type
 * @returns {string} string with readable converted transport name.
 */
function makeCls(transportType) {
  switch (transportType) {
    case 'bus':
      return 'bus';
    case 'flight':
      return 'plane';
    default:
      return '';
  }
}

const propTypes = {
  description: PropTypes.string.isRequired,
  time: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  transportType: PropTypes.string,
  type: PropTypes.string.isRequired,
  index: PropTypes.number,
  brand: PropTypes.string,
  originDetails: PropTypes.object,
  lineDetails: PropTypes.object,
};

const defaultProps = {
  transportType: 'bus',
};

/**
 * Component used as departure and place detail in itinerary.
 * @param {Object} props - Object props
 * @param {string} props.description - Indicates stage of the trip.
 * @param {string} props.time - Indicates trip's stage time.
 * @param {string} props.title - Indicates trip's stage name.
 * @param {string} props.transportType - Indicates trip's transport type .
 * @param {string} props.type - Indicates stage of the trip.
 * @param {string} props.index - Indicates trip index.
 * @param {string} props.brand - Indicates current brand.
 * @param {object} props.originDetails - Indicates trip's origin data.
 * @param {object} props.lineDetails - Indicates line provider data.
 * @param {boolean} props.lineDetails.copyrightProtected - Indicates if data is protected.
 * @param {string} props.lineDetails.siteUrl - Indicates line's site url.
 * @param {string} props.lineDetails.logoUrl - Indicates line's logo url.
 * @param {string} props.lineDetails.providerName - Indicates line's provider name.
 * @returns {JSX.Element} React component for Trip's timeline departure and place stage .
 */
const PlaceItem = ({
  description,
  time,
  title,
  transportType,
  type,
  index,
  brand,
  originDetails,
  lineDetails: { copyrightProtected = false, siteUrl, logoUrl, providerName, abbr } = {},
}) => {
  const { t } = useTranslation('trips');
  const transportCls = makeCls(transportType);
  const hasCoordinates = originDetails.coordinates?.lat || originDetails.coordinates?.long;
  const features = useWhitelabelFeatures();
  const showTerminal = features.SHOW_TERMINAL_NAME_AT_ITINERARY_ITEM;
  const validatedLogoUrl = logoUrl ?? dataFinder('lines', abbr)?.logoUrl;

  return (
    <>
      {brand === 'brasilia' && (
        <Text weight="semibold">
          {t('route')} {index}
        </Text>
      )}
      <div className={`route-detail-row ${type}`}>
        <div className="rd-left">
          <p>
            <b>{time}</b>
          </p>
        </div>

        <div className="rd-center">
          <Icon type="Bus" color="primary" size="L" />
        </div>

        <div className={`rd-right ${transportCls}`}>
          <p>
            <b>{title}</b>
            {showTerminal && <span>{description}</span>}
            {originDetails.address && <p>{originDetails.address}</p>}
          </p>
          {hasCoordinates ? (
            <Link
              newTab
              type="primary"
              size="XS"
              href={`https://www.google.com/maps/search/?api=1&query=${originDetails.coordinates.lat},${originDetails.coordinates.long}`}
              text={t('see_location')}
            />
          ) : null}
          {copyrightProtected ? (
            <p>
              <b>{siteUrl}</b>
            </p>
          ) : (
            <img src={validatedLogoUrl} alt={providerName} />
          )}
        </div>
      </div>
    </>
  );
};

PlaceItem.propTypes = propTypes;
PlaceItem.defaultProps = defaultProps;

export default PlaceItem;
