import React, { useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import PropTypes from 'prop-types';
import OverlayView from 'components/OverlayView';
import 'styles/components/modals/TimeoutModal';

const propTypes = {
  isExpired: PropTypes.bool.isRequired,
  destination: PropTypes.string.isRequired,
  origin: PropTypes.string.isRequired,
  departureIsOpenTicket: PropTypes.bool.isRequired,
  returnIsOpenTicket: PropTypes.bool.isRequired,
  searchDepartureDate: PropTypes.string.isRequired,
  searchReturnsDate: PropTypes.string.isRequired,
  departureDate: PropTypes.string.isRequired,
  returnDate: PropTypes.string.isRequired,
  roundTrip: PropTypes.bool.isRequired,
  isExchange: PropTypes.bool.isRequired,
  encodedPassengersSelection: PropTypes.string.isRequired,
};

const TimeoutModal = ({
  isExpired,
  destination,
  origin,
  departureIsOpenTicket,
  returnIsOpenTicket,
  searchDepartureDate,
  searchReturnsDate,
  departureDate,
  returnDate,
  roundTrip,
  isExchange,
  encodedPassengersSelection = 'A1',
}) => {
  const { features } = useSelector((state) => state.whitelabelConfig);
  const history = useHistory();
  const { t } = useTranslation('general');

  const handleGotoSearch = useCallback(() => {
    const departure = departureIsOpenTicket
      ? moment(searchDepartureDate, 'DD-MM-YYYY').format('DD-MMM-YY')
      : moment(departureDate).format('DD-MMM-YY');

    let url = `/search/${origin}/${destination}/${departure}/`;

    if (roundTrip) {
      const returns = returnIsOpenTicket
        ? moment(searchReturnsDate, 'DD-MM-YYYY').format('DD-MMM-YY')
        : moment(returnDate).format('DD-MMM-YY');

      url += `${returns}/`;
    }

    if (isExchange) {
      history.push('/exchange');
    } else {
      history.push(`${url}p/${encodedPassengersSelection}/departures`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    departureDate,
    departureIsOpenTicket,
    destination,
    history,
    origin,
    returnDate,
    returnIsOpenTicket,
    roundTrip,
    searchDepartureDate,
    searchReturnsDate,
  ]);

  useEffect(() => {
    if (isExpired) {
      const timer = setTimeout(() => {
        handleGotoSearch();
      }, 1000 * 10);
      return () => clearTimeout(timer);
    }
  }, [handleGotoSearch, isExpired]);

  if (!features.PURCHASE_TIMER_ENABLED) {
    return null;
  }

  return (
    <OverlayView title="time_out" visible={isExpired} headerButton={false} visibleHeader={false}>
      <div className="timeout-modal">
        <h4>{t('time_is_over')}</h4>
        <i className="timeout-modal-icon" />
        <p className="content">{t('time_out_select_trip_again')}</p>
        <button className="button-primary" onClick={() => handleGotoSearch()}>
          {t('button.select_new_schedule')}
        </button>
        <p className="footer">{t('text.you_will_be_redirected')}...</p>
      </div>
    </OverlayView>
  );
};

TimeoutModal.propTypes = propTypes;

export default TimeoutModal;
