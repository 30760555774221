import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { generateGoogleMapsSearchUrl } from 'utils/urls';
import usePurchase from '../../hooks/store/usePurchase';
import DownloadTickets from '../../ui/molecules/DownloadTickets';

/**
 * DownloadTicketsContainer Component
 *
 * React component serving as a container for the DownloadTickets molecule. It orchestrates the presentation of ticket information, generating URLs for Google Maps, and providing necessary data for rendering.
 *
 * @component
 * @param {string} hrefDeparture - The URL for downloading the departure ticket.
 * @param {string} hrefReturn - The URL for downloading the return ticket.
 * @param {string} instructions - Additional instructions or information.
 * @param {string} isDepartureOpen - Indicates whether the departure ticket information is open.
 * @param {string} isReturnOpen - Indicates whether the return ticket information is open.
 * @param {string} status - The status of the ticket (e.g., pending, confirmed).
 * @param {string} isOpenTicket - Indicates whether the ticket is open.
 * @param {string} displayDownloadTicketsButton - Indicates whether to display the download tickets button.
 * @param {string} email - The email associated with the ticket.
 * @param {string} departsTransporterKey - Transporter key for departure.
 * @param {string} returnsTransporterKey - Transporter key for return.
 * @param {string} token - The purchase token
 *
 * @returns {JSX.Element} - The rendered DownloadTicketsContainer component.
 */
const DownloadTicketsContainer = ({
  hrefDeparture,
  hrefReturn,
  instructions,
  isDepartureOpen,
  isReturnOpen,
  status,
  isOpenTicket,
  displayDownloadTicketsButton,
  email,
  departsTransporterKey,
  returnsTransporterKey,
  token,
}) => {
  const useTripRouteInfo = () => {
    const purchase = usePurchase();

    const tripRouteInfo = {
      departureOriginTime: purchase.departs.departure,
      departureOriginTerminal: purchase.departs.origin.name,
      departureOriginCity: purchase.departs.origin.cityName,
      departureOriginLat: purchase.departs.origin.coordinates.lat,
      departureOriginLong: purchase.departs.origin.coordinates.long,
      departureDestinationTime: purchase.departs.arrival,
      departureDestinationTerminal: purchase.departs.destination.name,
      departureDestinationCity: purchase.departs.destination.cityName,
      departureDestinationLat: purchase.departs.destination.coordinates.lat,
      departureDestinationLong: purchase.departs.destination.coordinates.long,
      returnOriginTime: purchase.returns?.departure,
      returnOriginTerminal: purchase.returns?.origin?.name,
      returnOriginCity: purchase.returns?.origin?.cityName,
      returnOriginLat: purchase.returns?.origin?.coordinates.lat,
      returnOriginLong: purchase.returns?.origin?.coordinates.long,
      returnDestinationTime: purchase.returns?.arrival,
      returnDestinationTerminal: purchase.returns?.destination?.name,
      returnDestinationCity: purchase.returns?.destination?.cityName,
      returnDestinationLat: purchase.returns?.destination?.coordinates.lat,
      returnDestinationLong: purchase.returns?.destination?.coordinates.long,
    };

    return tripRouteInfo;
  };

  const {
    departureOriginTime,
    departureOriginTerminal,
    departureOriginCity,
    departureOriginLat,
    departureOriginLong,
    departureDestinationTime,
    departureDestinationTerminal,
    departureDestinationCity,
    departureDestinationLat,
    departureDestinationLong,
    returnOriginTime,
    returnOriginTerminal,
    returnOriginCity,
    returnOriginLat,
    returnOriginLong,
    returnDestinationTime,
    returnDestinationTerminal,
    returnDestinationCity,
    returnDestinationLat,
    returnDestinationLong,
  } = useTripRouteInfo();

  const departureOriginMaps = generateGoogleMapsSearchUrl({
    lat: departureOriginLat,
    long: departureOriginLong,
  });
  const departureDestinationMaps = generateGoogleMapsSearchUrl({
    lat: departureDestinationLat,
    long: departureDestinationLong,
  });
  const returnOriginMaps = generateGoogleMapsSearchUrl({
    lat: returnOriginLat,
    long: returnOriginLong,
  });
  const returnDestinationMaps = generateGoogleMapsSearchUrl({
    lat: returnDestinationLat,
    long: returnDestinationLong,
  });

  return (
    <DownloadTickets
      hrefDeparture={hrefDeparture}
      hrefReturn={hrefReturn}
      instructions={instructions}
      isDepartureOpen={isDepartureOpen}
      isReturnOpen={isReturnOpen}
      status={status}
      isOpenTicket={isOpenTicket}
      displayDownloadTicketsButton={displayDownloadTicketsButton}
      email={email}
      departsTransporterKey={departsTransporterKey}
      returnsTransporterKey={returnsTransporterKey}
      departureDate={moment(departureOriginTime).format('DD MMM')}
      departureOriginTime={moment(departureOriginTime).format('LT')}
      departureOriginTerminal={departureOriginTerminal}
      departureOriginCity={departureOriginCity}
      departureOriginLocation={departureOriginMaps}
      departureDestinationTime={moment(departureDestinationTime).format('LT')}
      departureDestinationTerminal={departureDestinationTerminal}
      departureDestinationCity={departureDestinationCity}
      departureDestinationLocation={departureDestinationMaps}
      returnDate={moment(returnOriginTime).format('DD MMM')}
      returnOriginTime={moment(returnOriginTime).format('LT')}
      returnOriginTerminal={returnOriginTerminal}
      returnOriginCity={returnOriginCity}
      returnOriginLocation={returnOriginMaps}
      returnDestinationTime={moment(returnDestinationTime).format('LT')}
      returnDestinationTerminal={returnDestinationTerminal}
      returnDestinationCity={returnDestinationCity}
      returnDestinationLocation={returnDestinationMaps}
      token={token}
    />
  );
};

DownloadTicketsContainer.propTypes = {
  hrefDeparture: PropTypes.string,
  hrefReturn: PropTypes.string,
  instructions: PropTypes.string,
  isDepartureOpen: PropTypes.string,
  isReturnOpen: PropTypes.string,
  status: PropTypes.string,
  isOpenTicket: PropTypes.string,
  displayDownloadTicketsButton: PropTypes.string,
  email: PropTypes.string,
  departsTransporterKey: PropTypes.string,
  returnsTransporterKey: PropTypes.string,
  token: PropTypes.string,
};

export default DownloadTicketsContainer;
