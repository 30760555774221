import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Spacing, Text, Visibility } from '@reservamos/elements';
import { toggleMenu } from '@/actions';
import PropTypes from 'prop-types';
import AgencyHeader from './AgencyHeader';
import BrandLogo from './BrandLogo';
import MenuButton from '../ui/atoms/MenuButton';
import LngButton from '../ui/atoms/LngButton';
import LoyaltyButton from './LoyaltyButton';
import useLoyaltyPrograms from '../loyalty/context/useLoyaltyPrograms';
import useWhitelabelTheme from '../hooks/whitelabel/useWhitelabelTheme';

const DesktopHeader = ({ hideLoyalty }) => {
  const {
    features,
    env,
    copies: { provider },
  } = useSelector((state) => state.whitelabelConfig);
  const { colors } = useWhitelabelTheme();
  const dispatch = useDispatch();
  const { t } = useTranslation('general');
  const {
    costaPass: { config: costaPassConfig },
  } = useLoyaltyPrograms();

  const onMenuButtonClick = () => dispatch(toggleMenu());

  return (
    <div className="topbar-desktop">
      <div className="topbar-desktop-l">
        <BrandLogo />
        {features.SHOW_HEADER_LABEL && (
          <Visibility type="hideForMedium">
            <Text color={colors.headerText || 'white'} size="S">
              <em>{t('header', { context: env.brand, brand: provider.name })}</em>
            </Text>
          </Visibility>
        )}
      </div>

      <div className="topbar-desktop-r">
        <AgencyHeader />
      </div>

      <Spacing alignItems="center">
        {!hideLoyalty && features.DOTERS_ENABLED && (
          <LoyaltyButton id="siempre-plus-widget-desktop-header" isLink />
        )}
        {!hideLoyalty && features.COSTAPASS_ENABLED && (
          <LoyaltyButton
            id="siempre-plus-widget-desktop-header-costapass"
            isLink
            extraConfig={costaPassConfig}
          />
        )}
        <LngButton />
        {features.SHOW_SIDEBAR_MENU && <MenuButton onClick={onMenuButtonClick} />}
      </Spacing>
    </div>
  );
};

DesktopHeader.propTypes = {
  hideLoyalty: PropTypes.bool,
};

export default DesktopHeader;
