import React, { useRef } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Text } from '@reservamos/elements';
import { useTranslation } from 'react-i18next';
import adyenLogo from 'images/payment/adyen.svg';
import mastercard from 'images/payment/mastercard.svg';
import visa from 'images/payment/visa.svg';
import amex from 'images/payment/americanexpress.svg';
import useAdyen from 'hooks/libs/useAdyen';
import SaveCardCheckbox from '../SaveCardCheckbox';
import Installments from '../Installments';
import LoyaltyLink from '../../../ui/atoms/LoyaltyLink';
import 'styles/components/AdyenForm';
import 'styles/components/purchase/PaymentOptions';

const AdyenForm = ({ showAdyenForm, showCheckbox }) => {
  const { t } = useTranslation();
  const { features } = useSelector((state) => state.whitelabelConfig);
  const adyenElement = useRef(null);
  const siemprePlus = useSelector((state) => state.siemprePlus);

  useAdyen(adyenElement, showAdyenForm);

  return (
    <>
      {showAdyenForm && (
        <div>
          {features.SHOW_CREDIT_DEBIT_LOGOS && <Text size="S">{t('purchase:accepted_cards')}</Text>}
          {features.SHOW_ADYEN_CARD_LOGOS && (
            <div className="cards-logos">
              <img src={visa} />
              <img src={mastercard} />
              <img src={amex} />
            </div>
          )}

          {features.INSTALLMENTS_ENABLED && <Installments />}
          <div className="adyen-form-inputs" ref={adyenElement} />
          <div className="adyen-logo-container">
            {showCheckbox && <SaveCardCheckbox />}
            <img src={adyenLogo} height="20px" width="60px" />
          </div>
        </div>
      )}
      <LoyaltyLink id="link-home" visible={!siemprePlus.get('user')} />
    </>
  );
};

AdyenForm.propTypes = {
  showAdyenForm: PropTypes.bool,
  showCheckbox: PropTypes.bool,
};

AdyenForm.defaultProps = {
  showAdyenForm: false,
  showCheckbox: false,
};

export default AdyenForm;
