import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

const useWhitelabelTheme = () => {
  const { t } = useTranslation();
  const whitelabelTheme = useSelector((state) => state.whitelabelConfig.env.theme);
  /**
   * Get service type icon and label
   * @param {*} service - Service type
   * @returns - Icon and label
   */
  const getServiceType = (service) => {
    if (!whitelabelTheme.serviceTypes) {
      // If the translation key not found, just use service as the label
      const translationKey = `type.${service}`;
      const attemptedLabel = t(`trips:${translationKey}`);
      const label = attemptedLabel === translationKey ? service : attemptedLabel;
      return { icon: 'Bus', label };
    }
    return {
      icon:
        whitelabelTheme.serviceTypes[service]?.icon ||
        whitelabelTheme.serviceTypes.default?.icon ||
        'Bus',
      label:
        whitelabelTheme.serviceTypes[service]?.label ||
        whitelabelTheme.serviceTypes.default?.label ||
        service,
      hasMultiplePricing:
        whitelabelTheme.serviceTypes[service]?.hasMultiplePricing ||
        whitelabelTheme.serviceTypes.default?.hasMultiplePricing ||
        false,
    };
  };

  return {
    ...whitelabelTheme,
    purchaseStepsIcons: whitelabelTheme.purchaseStepsIcons || {},
    amenities: whitelabelTheme.amenities || {},
    paymentStatusIcons: whitelabelTheme.paymentStatusIcons || {},
    resultBoxStyle: whitelabelTheme.resultBoxStyle || '',
    getServiceType,
    resultsCTAColor: whitelabelTheme.resultsCTAColor || 'primary',
    headerTextColor: whitelabelTheme.headerTextColor || 'white',
  };
};

export default useWhitelabelTheme;
