import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Icon, Text, Visibility } from '@reservamos/elements';
import './NavbarFilters.scss';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom/cjs/react-router-dom';
import { trackEvent } from 'user-analytics';
import { FILTERS_MODAL_OPENED } from 'constants/TrackEvents';
import { isSeatsOnResultActivated } from 'utils/seats';
import NavbarFiltersModal from './NavbarFiltersModal';
import FilterButton from '../../../../ui/atoms/FilterButton';
import useAvailableSchedulesOptions from '../../../../hooks/useAvailableSchedulesOptions';
import useSortOptions from '../../../../hooks/useSortOptions';
import useAvailableCategories from '../../../../hooks/useAvailableCategories';
import useAvailableSchedulesProvidersOptions from '../../../../hooks/useAvailableSchedulesProvidersOptions';
import useAvailableTripOptions from '../../../../hooks/useAvailableTripOptions';
import { ShortAccessFilters } from '../ShortAccessFilters';
import useStopsAvailable from '../../../../hooks/useAvailableStops';
import { replaceURLParam } from '../../../../utils/urls';
import useShortAccessFilters from '../../../../hooks/useShortAccessFilters';
import useFiltersContext from '../../../../hooks/useFilters';

const defaultFiltersData = {
  departureTime: ['none'],
  stops: ['none'],
  categories: [],
  tripOptions: [],
};

const DEPARTURE_TIME = 'departureTime';
const STOPS = 'stops';
const CATEGORIES = 'categories';
const PET_FRIENDLY = 'pet_friendly';
const SUPPORT_WOMAN = 'supportWoman';
const SORT_BY = 'sortBy';
const TRIP_OPTIONS = 'tripOptions';

/**
 * Represents the Navbar Filters component.
 * @param {Object} props - The component props.
 * @param {Function} props.onApplyFilters - Function to apply the selected filters.
 * @param {Function} props.onChangeSort - Function to handle sort option change.
 * @param {string} props.way - The selected way (departure or return).
 * @param {Function} props.onResetFilters - Function to reset the filters.
 * @param {boolean} props.isProviderList - Flag indicating if the list is a provider list.
 * @param {boolean} props.isOpenTicketList - Flag indicating if the list is a open ticket list.
 */
const NavbarFilters = ({
  onApplyFilters,
  onChangeSort,
  way,
  onResetFilters,
  isProviderList,
  isOpenTicketList,
}) => {
  const { features } = useSelector((state) => state.whitelabelConfig);
  const {
    scheduleFilters: scheduleFiltersTrips,
    stopsFilters,
    categoriesFilters,
    tripOptionsFilters,
    sortBy: sortByTrips,
    scheduleFiltersProviders,
    sortByProviders,
  } = useFiltersContext();

  const isSeatsOnResults = isSeatsOnResultActivated();

  const scheduleFilters = isProviderList ? scheduleFiltersProviders : scheduleFiltersTrips;
  const sortBy = isProviderList ? sortByTrips : sortByProviders;

  const [shortAccessFilters] = useShortAccessFilters();

  const isFlat = !isSeatsOnResults && features.FUNNEL_STYLE === 'FLAT';
  const defaultSortBy = features.SORT_TRIPS_BY_PRICE ? 'price' : 'departure';

  /**
   * This ref is used to save the filters selected, it is a ref used to avoid re-renders.
   */
  const filtersSelected = useRef({
    [DEPARTURE_TIME]: scheduleFilters ? [...scheduleFilters] : ['none'],
    [STOPS]: stopsFilters ? [...stopsFilters] : ['none'],
    [CATEGORIES]: categoriesFilters ? [...categoriesFilters] : [],
    [TRIP_OPTIONS]: tripOptionsFilters ? [...tripOptionsFilters] : [],
  });

  const [showModal, setShowModal] = useState(false);

  /**
   * State and ref to save the filters to add to the short access list.
   */
  const [sortOptionBy, setSortOptionBy] = useState(sortBy);

  // Hooks to get filters
  const scheduleOptions = useAvailableSchedulesOptions(way, true) || [];
  const scheduleProviderOptions = useAvailableSchedulesProvidersOptions(way, true) || [];
  const sortOptions = useSortOptions(true, isProviderList) || [];
  const availableCategories = useAvailableCategories(way, true) || [];
  const availableStops = useStopsAvailable(way, true) || [];
  const availableTripOptions = useAvailableTripOptions(way) || [];

  // Get filters param from url
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const filtersParam = params.get('filters') ?? null;

  /**
   * This variable contains the categories to show in the modal.
   * This exclude the pet friendly and woman seat categories becuase they are show in a differente way.
   */
  const availableCategoriesToUse = availableCategories.filter(
    (category) => category.value !== PET_FRIENDLY && category.value !== SUPPORT_WOMAN,
  );

  /** Pet friendly and woman category are filter because these categories are painted different in a modal. */
  const petFriendlyCategory = availableCategories.find(
    (category) => category.value === PET_FRIENDLY,
  );
  const womanCategory = availableCategories.find((category) => category.value === SUPPORT_WOMAN);

  /** Handles the schedule filter selection */
  const handleOnFilterSelected = (filter) => {
    const { filterType, value } = filter;
    const filtersSelectedCopy = [...filtersSelected.current[filterType]];

    if (!filter.isActive) {
      const index = filtersSelectedCopy.findIndex((item) => item === value);
      if (index !== -1) {
        filtersSelectedCopy.splice(index, 1);
      }
    } else {
      if (filtersSelectedCopy.includes(value)) return;
      if (filtersSelectedCopy.includes('none')) {
        const noneIndex = filtersSelectedCopy.findIndex((item) => item === 'none');
        filtersSelectedCopy.splice(noneIndex, 1);
      }
      filtersSelectedCopy.push(value);
    }

    if (filtersSelectedCopy.length === 0 && filterType !== CATEGORIES) {
      filtersSelectedCopy.push('none');
    }
    filtersSelected.current[filterType] = [...filtersSelectedCopy];
  };

  /**
   * Handles the stops filter selection.
   * @param {Object} stop - The stop filter selected.
   */
  const handleOnStopsChanges = (stop) => {
    if (stop.isActive) {
      if (filtersSelected.current.stops.includes('none')) {
        filtersSelected.current.stops.splice(0, 1);
      }
      if (filtersSelected.current.stops.includes(stop.value)) return;
      filtersSelected.current.stops.push(stop.value);
    } else {
      const index = filtersSelected.current.stops.findIndex((item) => item === stop.value);
      filtersSelected.current.stops.splice(index, 1);
      if (filtersSelected.current.stops.length === 0) filtersSelected.current.stops.push('none');
    }
  };

  /**
   * Validate if a filter is available. This is done to unchecked the filter if it is not available.
   * @param {Object} filter - The filter to validate.
   * @param {string} filter.filterType - The filter filterType.
   * @param {string} filter.value - The filter value.
   * @returns Boolean - True if the filter is available, false otherwise.
   */
  const validateAvailability = ({ filterType, value }) => {
    /* If the value is set on the query param "filters", it means that the filter is available */
    if (filtersParam) {
      if (filtersParam.includes(value)) return true;
    }

    if (filterType === CATEGORIES) {
      if (isProviderList || isOpenTicketList) return false;
      return availableCategories?.find((category) => category.value === value)?.hasAvailability;
    }
    if (filterType === SORT_BY) {
      return Boolean(sortOptions?.find((sort) => sort.value === value));
    }
    if (filterType === DEPARTURE_TIME) {
      const scheduleOptionsToUse = isProviderList ? scheduleProviderOptions : scheduleOptions;
      return scheduleOptionsToUse?.find((schedule) => schedule.value === value)?.isAvailable;
    }
    if (filterType === STOPS && !isProviderList && !isOpenTicketList) {
      return availableStops?.some((filter) => filter.value === value);
    }
    if (filterType === TRIP_OPTIONS) {
      const isObject = typeof value === 'object';
      const isValid = availableTripOptions?.find(
        (tripOption) =>
          tripOption.value === value ||
          (isObject && JSON.stringify(tripOption.value) === JSON.stringify(value)),
      )?.hasAvailability;
      return isValid;
    }
  };

  /**
   * Handles the modal show.
   */
  const handleOnShowModal = () => {
    setShowModal(true);
    trackEvent(FILTERS_MODAL_OPENED);
  };

  /**
   * Handles the modal close.
   */
  const handleOnCloseModal = () => {
    setShowModal(false);
  };

  /**
   * Handle the apply filters click
   */
  const handleOnApply = () => {
    handleOnCloseModal();
    onApplyFilters(filtersSelected.current);
    onChangeSort(sortOptionBy, null);
  };

  /** Handle the apply filters click
   * @param {boolean} isSavedFiltersActivation - Indicates if the filters are applied from the saved filters (short access filters)
   */
  const handleOnShortAccessApply = (isSavedFiltersActivation) => {
    onApplyFilters(filtersSelected.current, isSavedFiltersActivation);
  };

  /** Function to reset/clean the filters */
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleOnResetFilters = () => {
    filtersSelected.current = { ...defaultFiltersData };
    setSortOptionBy(defaultSortBy);
    setShowModal(false);

    // It is passed true to indicate that the saved filters are loaded
    onResetFilters(true);
  };

  /**
   * Returns the number of filters selected.
   * @returns - The number of filters selected.
   */
  const filtersCount = () => {
    const scheduleFiltersCount = scheduleFilters?.filter((filter) => filter !== 'none').length || 0;
    const stopsFilterCount =
      (!isOpenTicketList &&
        !isProviderList &&
        stopsFilters?.filter((filter) => filter !== 'none').length) ||
      0;
    const categoriesFilterCount =
      (!isOpenTicketList &&
        !isProviderList &&
        categoriesFilters?.filter((filter) => filter !== 'none').length) ||
      0;
    const tripOptionsFilterCount =
      (!isOpenTicketList &&
        !isProviderList &&
        tripOptionsFilters?.filter((filter) => filter !== 'none').length) ||
      0;

    return scheduleFiltersCount + stopsFilterCount + categoriesFilterCount + tripOptionsFilterCount;
  };

  /**
   * Returns the filter object from the filter string param
   * @param {string} filter - The filter string
   * @returns Object - The filter object.
   * */
  const getFilterObj = (filter) => {
    const schedule = scheduleOptions.find((schedule) => schedule.value === filter);
    if (schedule) {
      return {
        ...schedule,
        isActive: true,
      };
    }
    const category = availableCategories.find((category) => category.value === filter);
    if (category) {
      return {
        ...category,
        isActive: true,
      };
    }
    const directStop = availableStops.find((stop) => stop.value === 'direct');
    if (filter === 'direct') {
      return {
        ...directStop,
        isActive: true,
      };
    }

    /* Check if filter param is part of available trip options filters */
    const tripOption = availableTripOptions.find((tripOption) => {
      return tripOption.id === filter;
    });
    if (tripOption) {
      return {
        ...tripOption,
        isActive: true,
      };
    }
  };

  /**
   * Handles the filter selected for trip options.
   */
  const handleOnTripOptionsChanges = (filter) => {
    if (filter.isActive) {
      /* Check if filter value is object or no */
      if (typeof filter.value === 'object') {
        /**
         * Validate that object values are not selected yet
         * This operation is done to avoid that the same object is selected twice
         */
        const isObjectSelected = filtersSelected.current[TRIP_OPTIONS].find((item) => {
          return JSON.stringify(item) === JSON.stringify(filter.value);
        });

        if (isObjectSelected) return;
      } else if (filtersSelected.current[TRIP_OPTIONS].includes(filter.value)) {
        /* Validate that string/number/boolean values are not selected */
        return;
      }

      filtersSelected.current[TRIP_OPTIONS].push(filter.value);
    } else {
      const index = filtersSelected.current[TRIP_OPTIONS].findIndex((item) => {
        if (typeof item === 'object' && typeof filter.value === 'object') {
          return JSON.stringify(item) === JSON.stringify(filter.value);
        }
        return item === filter.value;
      });
      filtersSelected.current[TRIP_OPTIONS].splice(index, 1);
    }
  };

  /**
   * Handles the filter selected and selects the function to handle that type of filter.
   * @param {Object} filter - The filter to select.
   */
  const onSelectOption = (filter) => {
    if (filter.filterType === CATEGORIES || filter.filterType === DEPARTURE_TIME) {
      handleOnFilterSelected(filter);
      return;
    }
    if (filter.filterType === STOPS) {
      handleOnStopsChanges(filter);
      return;
    }
    if (filter.filterType === SORT_BY) {
      setSortOptionBy(filter.value);
    }

    if (filter.filterType === TRIP_OPTIONS) {
      handleOnTripOptionsChanges(filter);
    }
  };

  /**
   * Function to handle the selection of a filter from the short access list
   * @param {Object} filter - Filter to be selected
   */
  const onSelectShortAccess = (filter) => {
    onSelectOption(filter, false);
    handleOnShortAccessApply(false);
  };

  /**
   * It checks the filters selected and remove the ones that are not available.
   * @param {Object} filterType - The type of filter to validate.
   * @returns The new filters selected.
   */
  const getUnavailableAfterUpdate = (filterType) => {
    let changed;
    const filtersCopy = [...filtersSelected.current[filterType]];
    filtersSelected.current[filterType].forEach((value, index) => {
      if (!validateAvailability({ filterType, value }) && value !== 'none') {
        filtersCopy.splice(index, 1);
        changed = true;
      }
    });
    return { filter: filtersCopy, changed };
  };

  useEffect(() => {
    /**
     * This algorithm look for the current filters and validate if they are available,
     * If they are not available, the filter is removed. This is due to the fact that the available filters
     * can change during the polling of data or between departure, return or date.
     */
    const { filter: categories, changed: categoriesChanged } =
      isOpenTicketList || isProviderList ? {} : getUnavailableAfterUpdate(CATEGORIES);

    const { filter: departureTime, changed: departureTimeChanged } =
      getUnavailableAfterUpdate(DEPARTURE_TIME);

    const { filter: tripOptions, changed: tripOptionsChanged } =
      getUnavailableAfterUpdate(TRIP_OPTIONS);

    if (departureTime.length === 0) departureTime.push('none');

    const { filter: stops, changed: stopsChanged } =
      isOpenTicketList || isProviderList ? {} : getUnavailableAfterUpdate(STOPS);
    if (!isOpenTicketList && !isProviderList && stops.length === 0) stops.push('none');

    if (categoriesChanged)
      filtersSelected.current[CATEGORIES] = categories ?? defaultFiltersData.categories;
    if (departureTimeChanged)
      filtersSelected.current[DEPARTURE_TIME] = departureTime ?? defaultFiltersData.departureTime;
    if (stopsChanged) filtersSelected.current[STOPS] = stops ?? defaultFiltersData.stops;
    if (tripOptionsChanged)
      filtersSelected.current[TRIP_OPTIONS] = tripOptions ?? defaultFiltersData.tripOptions;

    if (categoriesChanged || departureTimeChanged || tripOptionsChanged)
      onApplyFilters(filtersSelected.current, true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    availableCategories,
    scheduleOptions,
    scheduleProviderOptions,
    availableStops,
    availableTripOptions,
  ]);

  /**
   * If the filters changes, those are saved in the ref.
   * In this way if they are cleaned or change from another place, The values in the ref are updated too.
   * So if the modal applies or remove another filter, the filters selected are the correct ones.
   */
  useEffect(() => {
    filtersSelected.current = {
      [DEPARTURE_TIME]: [...(scheduleFilters ?? defaultFiltersData.departureTime)],
      [STOPS]: [...(stopsFilters ?? defaultFiltersData.stops)],
      [CATEGORIES]: [...(categoriesFilters ?? defaultFiltersData.categories)],
      [TRIP_OPTIONS]: [...(tripOptionsFilters ?? defaultFiltersData.tripOptions)],
    };
  }, [categoriesFilters, scheduleFilters, stopsFilters, tripOptionsFilters]);

  /**
   * Set filters from the filters param
   * If the filters param is not empty
   */
  useEffect(() => {
    if (filtersParam) {
      // Get filters from the param and iterate over them
      filtersParam.split(',').forEach((filter) => {
        const filterObj = getFilterObj(filter);
        // If the filter is available set it
        if (filterObj && validateAvailability(filterObj)) {
          onSelectOption(filterObj, false);
        }
      });
      handleOnApply();
      replaceURLParam('filters');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filtersParam]);

  const { t } = useTranslation('search');

  if (isOpenTicketList) return <Text>{t('label.not_filters_open_ticket')}</Text>;

  return (
    <div className={`wrapper-filter ${isFlat ? 'wrapper-filter-flat' : 'wrapper-filter-classic'}`}>
      {isFlat && (
        <Visibility type="hideForMobileOnly">
          <Text size="L" weight="bold">
            {t('label.customize_your_search')}
          </Text>
        </Visibility>
      )}
      <FilterButton
        onClick={handleOnShowModal}
        filtersCount={filtersCount()}
        isFlat={features.FUNNEL_STYLE === 'FLAT'}
      />
      {showModal && (
        <NavbarFiltersModal
          onCloseModal={handleOnCloseModal}
          scheduleFilters={scheduleFilters}
          stopsFilter={stopsFilters}
          handleOnSelectOption={onSelectOption}
          handleOnApply={handleOnApply}
          sortOptionBy={sortOptionBy}
          sort={sortBy}
          sortOptions={sortOptions}
          stopsAvailable={availableStops}
          scheduleOptions={isProviderList ? scheduleProviderOptions : scheduleOptions}
          categoriesOptions={availableCategoriesToUse}
          petFriendlyCategory={petFriendlyCategory}
          womanCategory={womanCategory}
          tripOptionsFilter={tripOptionsFilters}
          tripOptionsOptions={availableTripOptions}
          onResetFilters={handleOnResetFilters}
          isProviderList={isProviderList}
          isOpenTicketList={isOpenTicketList}
          categoriesFilter={categoriesFilters}
        />
      )}

      <div className="filter-tags">
        {shortAccessFilters.length ? (
          <>
            <Visibility type="showForMobileOnly">
              <Icon type="ChevronLeft" size="S" color="grayLight" />
            </Visibility>

            <div className="filter-tags-content">
              <ShortAccessFilters
                onSelect={onSelectShortAccess}
                way={way}
                isProviderList={isProviderList}
                isOpenTicketList={isOpenTicketList}
                hasParamsFilters={Boolean(filtersParam)}
              />
            </div>

            <Visibility type="showForMobileOnly">
              <Icon type="ChevronRight" size="S" color="grayLight" />
            </Visibility>
          </>
        ) : null}
      </div>
    </div>
  );
};

NavbarFilters.propTypes = {
  onApplyFilters: PropTypes.func.isRequired,
  onChangeSort: PropTypes.func.isRequired,
  way: PropTypes.oneOf(['departure', 'return']),
  onResetFilters: PropTypes.func.isRequired,
  isProviderList: PropTypes.bool,
  isOpenTicketList: PropTypes.bool,
};

export default NavbarFilters;
