import { getCountryCallingCode, formatPhoneNumber } from 'react-phone-number-input';

export default function normalizePhone(rawInput, country) {
  if (!rawInput) {
    return rawInput;
  }

  const input = rawInput.replace(/[^\d]/g, '');
  let value = input;
  if (input.length > 1) {
    const code = getCountryCallingCode(country);
    value = formatPhoneNumber(`+${code}${input}`);
  }

  return value;
}
