import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { MessageBox, Spacing } from '@reservamos/elements';
import useWhitelabelFeatures from 'hooks/whitelabel/useWhitelabelFeatures';
import LoyaltyWidget from 'components/LoyaltyWidget/LoyaltyWidget';
import useLoyaltyPrograms from '../../loyalty/context/useLoyaltyPrograms';
import './LoyaltyMessagesLink';

const CostaPassMessage = ({ show }) => {
  const {
    costaPass: { config },
  } = useLoyaltyPrograms();
  const features = useWhitelabelFeatures();
  const { t } = useTranslation('loyalty');

  if (!show || !features.COSTAPASS_ENABLED) return null;

  const message = t('receive_costapass_benefits');
  const cta = t('sign_cta', { context: 'costapass' });

  return (
    <div className="dotersMessagesContainer">
      <MessageBox roundCorners borderColor="primary" widthFull>
        <Spacing justifyContent="space-between">
          <Spacing size="S" alignItems="baseline" justifyContent="flex-start">
            <i className="costapass-logo" />
            <div className="primaryfont">
              <LoyaltyWidget
                linkText={message}
                hideIcon
                id="costapassLoyaltyMessages"
                variant="link-home"
                ctaText={cta}
                visible
                extraConfig={config}
              />
            </div>
          </Spacing>
        </Spacing>
      </MessageBox>
    </div>
  );
};

CostaPassMessage.propTypes = {
  show: PropTypes.bool,
};

export default CostaPassMessage;
