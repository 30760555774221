import { connect } from 'react-redux';
import { encodePassengers } from 'utils/Reserbus';
import TimeoutModal from './TimeoutModal';

const mapStateToProps = ({ purchase, search }) => {
  const passengersSelection = purchase.get('passengerSelection')?.toJS();
  const encodedPassengersSelection =
    (passengersSelection && encodePassengers(passengersSelection)) || 'A1';
  return {
    isExpired: purchase.get('isExpired'),
    origin: purchase.getIn(['departs', 'origin', 'id']),
    destination: purchase.getIn(['departs', 'destination', 'id']),
    departureIsOpenTicket: purchase.getIn(['departs', 'fragments', 0, 'openTicket']),
    returnIsOpenTicket: purchase.getIn(['returns', 'fragments', 0, 'openTicket']),
    searchDepartureDate: search.get('departureDate'),
    searchReturnsDate: search.get('returnDate'),
    departureDate: purchase.getIn(['departs', 'departure']),
    returnDate: purchase.getIn(['returns', 'departure']),
    roundTrip: purchase.get('roundTrip'),
    isExchange: purchase.get('isExchange'),
    encodedPassengersSelection,
  };
};

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...stateProps,
  ...dispatchProps,
  ...ownProps,
});

const TimeoutModalConnect = connect(mapStateToProps, null, mergeProps)(TimeoutModal);

export default TimeoutModalConnect;
