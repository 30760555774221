import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { Field, change } from 'redux-form';
import { useTranslation } from 'react-i18next';
import { fieldRenderer, countryRenderer } from 'utils/formRenderers';
import { getCountries, getCountryCallingCode } from 'react-phone-number-input/input';
import es from 'react-phone-number-input/locale/es.json';
import { Spacing } from '@reservamos/elements';
import normalizePhone from 'utils/normalizePhone';

const COMMON_COUNTRIES = ['US', 'MX', 'CO', 'BR'];

const PhoneNumberField = ({ ...props }) => {
  const { name, placeholder, initValue, isDisabled, hideCodeNumber } = props;
  const [country, setCountry] = useState(initValue);
  const { t } = useTranslation('general');
  const dispatch = useDispatch();

  const handleCountryChange = (_, value) => {
    const phoneCode = getCountryCallingCode(value);
    setCountry(value);
    dispatch(change('passengers', 'phoneCode', phoneCode));
    dispatch(change('purchaser', 'phoneCode', phoneCode));
  };

  const handleNormalize = (value) => {
    return normalizePhone(value, country);
  };
  const mostUsedOptions = {
    groupTitle: t('most_used'),
    subOptions: COMMON_COUNTRIES.map((country) => ({
      label: `${es[country]} +${getCountryCallingCode(country)}`,
      value: country,
    })),
  };
  const otherOptions = {
    groupTitle: t('others'),
    subOptions: getCountries()
      .filter((country) => {
        return !COMMON_COUNTRIES.includes(country);
      })
      .sort((a, b) => {
        return es[a].localeCompare(es[b]);
      })
      .map((country) => ({
        label: `${es[country]} +${getCountryCallingCode(country)}`,
        value: country,
      })),
  };
  const optionsObject = [mostUsedOptions, otherOptions];

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Spacing flexGrow size="S">
      {!hideCodeNumber && (
        <Field
          name="phoneCountry"
          id="phoneCountry"
          component={countryRenderer}
          onChange={handleCountryChange}
          country={country}
          defaultValue={country}
          options={optionsObject}
          isDisabled={isDisabled}
        />
      )}
      <Field
        type="tel"
        name={name}
        id={name}
        placeholder={placeholder}
        component={fieldRenderer}
        normalize={handleNormalize}
        isDisabled={isDisabled}
      />
      <Field type="hidden" name="phoneCode" id="phoneCode" component="input" />
    </Spacing>
  );
};

PhoneNumberField.propTypes = {
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  isDisabled: PropTypes.bool,
  initValue: PropTypes.string.isRequired,
  hideCodeNumber: PropTypes.bool,
};
export default PhoneNumberField;
