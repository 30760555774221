import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { FieldGroup, Select, Spacing } from '@reservamos/elements';
import moment from 'moment';
import { range } from 'lodash';
import { feedbackClass } from 'utils/formValidations';

/**
 * Get the days for a given year and month.
 * @param {string} year - The year.
 * @param {string} month - The month.
 * @returns {Array<{value: string, label: string}>} The days of the month.
 */
function getDays(year, month) {
  const currentDate = moment();
  const currentYear = currentDate.year();
  const currentMonth = currentDate.month() + 1; // months are 0-indexed in moment
  const currentDay = currentDate.date();
  const daysInMonth = moment(`${year}-${month}`, 'YYYY-MM').daysInMonth();

  return range(1, daysInMonth + 1)
    .filter((day) => {
      if (parseInt(year, 10) === currentYear && parseInt(month, 10) === currentMonth) {
        return day <= currentDay;
      }
      return true;
    })
    .map((day) => {
      const dayNumber = day.toString().padStart(2, '0');
      return {
        value: dayNumber,
        label: dayNumber,
      };
    });
}

/**
 * Get the months for a given year.
 * @param {string} year - The year.
 * @returns {Array<{value: string, label: string}>} The months of the year.
 */
function getMonths(year) {
  const currentYear = moment().year();
  const currentMonth = moment().month() + 1; // months are 0-indexed in moment

  const months = moment.monthsShort();
  const filteredMonths = year < currentYear ? months : months.slice(0, currentMonth);

  return filteredMonths.map((month, index) => {
    const monthNumber = (index + 1).toString().padStart(2, '0');

    return {
      value: monthNumber,
      label: `${monthNumber} - ${month}`,
    };
  });
}

/**
 * Get the years based on passenger type.
 * @param {string} passengerType - The type of passenger (adult, child, infant).
 * @returns {Array<{value: number, label: number}>} The years.
 */
function getYears(passengerType) {
  const passengerAgeRanges = { adult: 100, child: 19, infant: 2 };
  const currentYear = moment().year();
  const limitYear = currentYear - passengerAgeRanges[passengerType] - 1;

  const years = range(currentYear, limitYear, -1);

  return years.map((year) => ({ value: year, label: year }));
}

/**
 * BirthDateField component.
 * @param {Object} props - The component props.
 * @param {string} props.passengerType - The type of passenger (adult, child, infant).
 * @param {Object} props.input - The input props from redux-form.
 * @param {Object} props.meta - The meta props from redux-form.
 * @returns {JSX.Element} The rendered component.
 */
const BirthDateField = ({ passengerType, input, meta }) => {
  const { t } = useTranslation('passengers');
  const dateArray = input.value.split('-');
  const year = dateArray[0] ?? '';
  const month = dateArray[1] ?? '';
  const day = dateArray[2] ?? '';

  /**
   * Handle date change.
   * @param {string} type - The type of date part (day, month, year).
   * @param {string} value - The new value.
   */
  function handleDateChange(type, value) {
    let newDate;

    switch (type) {
      case 'day':
        newDate = `${year}-${month}-${value}`;
        break;
      case 'month':
        newDate = `${year}-${value}-${day}`;
        break;
      case 'year':
        newDate = `${value}-${month}-${day}`;
        break;
      default:
    }

    input.onChange(newDate);
  }

  return (
    <>
      <FieldGroup label={t('birth_date')}>
        <Spacing isResponsive={false} size="XS" flexGrow>
          <Select
            id="year-select"
            placeholder={t('birth_date_year')}
            value={year}
            hasError={Boolean(meta.touched && meta.error && !year)}
            options={getYears(passengerType)}
            onChange={(e) => handleDateChange('year', e.target.value)}
          />
          <Select
            id="month-select"
            placeholder={t('birth_date_month')}
            value={month}
            hasError={Boolean(meta.touched && meta.error && !month)}
            options={getMonths(year)}
            onChange={(e) => handleDateChange('month', e.target.value)}
            className={`form-input ${feedbackClass(meta)}`}
            isDisabled={!year}
          />
          <Select
            id="day-select"
            placeholder={t('birth_date_day')}
            value={day}
            hasError={Boolean(meta.touched && meta.error && !day)}
            options={getDays(year, month)}
            onChange={(e) => handleDateChange('day', e.target.value)}
            isDisabled={!month}
          />
        </Spacing>
      </FieldGroup>

      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <Field {...input} type="hidden" component="input" />
    </>
  );
};

BirthDateField.propTypes = {
  passengerType: PropTypes.oneOf(['adult', 'child', 'infant']).isRequired,
  // Redux-form props
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
};

export default BirthDateField;
