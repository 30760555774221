import * as FullStory from '@fullstory/browser';
import defaultEnvs from 'config/defaultEnvs';
import { setConfig as setApiConfig } from 'js-api-client';
import Cookies from 'js-cookie';
import { browserName, deviceType, osName } from 'react-device-detect';
import OneSignal from 'react-onesignal';
import { getDistinctId, load as loadSegment, trackEvent } from 'user-analytics';
import initMoment from 'utils/initMoment';
import { CurrentLang } from 'utils/lang';
import initSmartBanner from 'utils/smartBanner';
import { getProductType } from 'utils/Reserbus';
import adyen from '../payments/core/engines/adyen';
import cybersource from '../payments/core/engines/cybersource';
import { loadSearchWidgetScript } from './utils/searchWidget';
import kushki from '../payments/core/engines/kushki';
import errorMonitoring from './errorMonitoring';
import growthBook from './services/growthBook';
import store from './store';
import loadBotlers from './utils/botlers';
import getUserHasAcceptedPrivacyPolicy from './utils/getUserHasAcceptedPrivacyPolicy';
import loadRiskified from './utils/loadRiskified';
import redirectHttps from './utils/redirectHttps';
import { getSessionId, setInitialSessionId } from './utils/session';
import { initTaplytics } from './utils/taplytics';
import fingerprintService from './services/userFingerprint/userFingerprint';
import cieloEngine from '../payments/core/engines/cielo';

// Used to distinguish between mobile and desktop client
const isDesktop = getProductType() === 'desktop';

/**
 * Initialize all the services needed using the tenant config
 * @param {Object} whitelabelConfig - tenant config
 * @returns {Promise} - Promise that resolves when all the services are initialized
 */
async function initializeClientServices(whitelabelConfig) {
  if (!window) return;

  const { env: whitelabelEnvs, features: whitelabelFeatures } = whitelabelConfig;

  // Load search widget style
  if (whitelabelEnvs.searchWidget.useDynamicLoad)
    loadSearchWidgetScript(
      whitelabelEnvs.searchWidget.version,
      whitelabelEnvs.searchWidget.useDynamicLoad,
    );

  redirectHttps();

  if (defaultEnvs?.sentry) {
    errorMonitoring.init(defaultEnvs.sentry);
  }

  const storeInstance = store.createInstance({ whitelabelConfig });

  // moment
  initMoment(whitelabelEnvs.lang.default, whitelabelFeatures.HOUR_FORMAT);

  // session id used for antifraud beacons
  setInitialSessionId();

  // smart banner for invite to install mobile app
  initSmartBanner(whitelabelEnvs.smartBanner);

  const defaultLanguage = whitelabelEnvs.lang.default;
  const i18nextLng =
    typeof window !== 'undefined' && window.localStorage
      ? window.localStorage.getItem('i18nextLng') || defaultLanguage
      : defaultLanguage;

  // Reservamos API Client
  setApiConfig({
    searchUrl: whitelabelEnvs.api.searchUrl,
    purchaseUrl: whitelabelEnvs.api.purchaseUrl,
    coreUrl: whitelabelEnvs.coreApi?.url,
    coreVersion: whitelabelEnvs.coreApi?.version,
    apiKey: isDesktop ? whitelabelEnvs.api.desktopKey : whitelabelEnvs.api.mobileKey,
    userAuthToken: Cookies.get('token'),
    headers: {
      'accept-language': i18nextLng,
    },
  });

  // conekta
  if (window.Conekta) {
    window.Conekta.setPublicKey(whitelabelEnvs.conekta.pubKey);
  }

  // GrowthBook
  const { brand, growthBook: growthBookConfig } = whitelabelEnvs;

  const browser = browserName.toLowerCase();
  const device = deviceType.toLowerCase();
  const os = osName.toLowerCase();
  const productType = isDesktop ? 'web' : 'web-mobile';

  if (growthBookConfig?.enabled) {
    growthBook.init({
      enableDevMode: growthBookConfig.enableDevMode,
      trackingCallback: (experiment, result) => {
        // Send the experiment data to mixpanel
        trackEvent('$experiment_started', {
          'Experiment name': experiment.key,
          'Variant value': result.value,
          '$source': 'growthbook',
        });
      },
    });
    growthBook.setAttributes({
      brand,
      browser,
      device,
      os,
      product: productType,
    });
    growthBook.loadFeatures(growthBookConfig.featuresEndpoint);
  }

  // User Fingerprint
  const { userFingerprint } = whitelabelEnvs;

  if (userFingerprint?.enabled) {
    await fingerprintService.init(userFingerprint);
    fingerprintService.setUserMetadata({
      tag: {
        tenant: brand,
        productType,
      },
    });
  }

  /**
   * Returns the super props to sent in all the mixpanel events
   * @returns {Object} superProps
   */
  const getMixpanelSuperProps = () => {
    const superProps = {
      product: productType,
    };

    return superProps;
  };

  const mixpanelSuperProps = getMixpanelSuperProps();

  /**
   * Register fingerprint into the Mixpanel superProps
   */
  const syncFingerprintAndMixpanel = async ({ distinctId }) => {
    // The fingerprint is gotten only if we are in the initial pages to save resources
    const pathValidForGetFingerprint =
      window.location.pathname.includes('/search') || window.location.pathname === '/';

    // Adding device fingerprint to mixpanel super pros and distinct id to user fingerprint
    fingerprintService.setUserMetadata({
      linkedId: distinctId,
    });

    let userFingerprintValue;
    if (pathValidForGetFingerprint) {
      userFingerprintValue = await fingerprintService.setUpFingerprint();
    } else {
      userFingerprintValue = await fingerprintService.getCachedFingerprint();
    }
    if (userFingerprintValue)
      window.mixpanel.register({
        [fingerprintService.getTrackingKey()]: userFingerprintValue,
      });
  };

  /**
   * Function executed when the tracking engine is loaded
   */
  const onLoadedSegment = () => {
    // This is done to make know another libraries that mixpanel is ready to be used
    const distinctId = getDistinctId();

    syncFingerprintAndMixpanel({ distinctId });

    if (growthBookConfig?.enabled) {
      growthBook.setAttributes({ id: distinctId });
    }

    window.dispatchEvent(new CustomEvent('MixpanelReady'));
  };

  // segment
  loadSegment(whitelabelEnvs.segment.token, {
    ...whitelabelEnvs.segment.options,
    superProps: mixpanelSuperProps,
    onLoaded: onLoadedSegment,
  });

  if (whitelabelEnvs.fullstory?.enabled) {
    FullStory.init({
      orgId: whitelabelEnvs.fullstory.orgId,
      debug: !__PROD__,
    });
  }

  // cybersource
  if (!whitelabelFeatures.DYNAMIC_CYBERSOURCE_CREDENTIALS && whitelabelEnvs.cybersource?.enabled) {
    const { orgId, merchantId, isSandbox } = whitelabelEnvs.cybersource;
    cybersource.initialize({ orgId, merchantId, isSandbox, sessionId: getSessionId() });
  }

  // riskified
  loadRiskified(whitelabelEnvs.riskified);

  if (whitelabelEnvs.botlers && whitelabelEnvs.botlers.enabled) {
    loadBotlers(whitelabelEnvs.botlers.bmid);
  }

  if (whitelabelEnvs.kushki && whitelabelEnvs.kushki.enabled) {
    const { merchantId, isSandbox } = whitelabelEnvs.kushki;
    kushki.initialize({ merchantId, inTestEnvironment: isSandbox });
  }

  if (whitelabelEnvs.adyen && whitelabelEnvs.adyen.enabled) {
    if (whitelabelEnvs.adyen.creditCard.enabled) {
      adyen.initialize({
        ...whitelabelEnvs,
        lang: { current: CurrentLang(), default: whitelabelEnvs.lang.default },
      });
    }
  }

  if (whitelabelEnvs.cielo?.enabled) {
    const { tokenizeEndpoint } = whitelabelEnvs.cielo;
    cieloEngine.init({ tokenizeEndpoint });
  }

  // One Signal
  const hasAcceptedPrivacyPolicy = getUserHasAcceptedPrivacyPolicy();
  if (whitelabelEnvs.oneSignal.enabled && hasAcceptedPrivacyPolicy) {
    OneSignal.init({
      appId: whitelabelEnvs.oneSignal.appId,
      notifyButton: {
        enable: true,
      },
    });
  }

  // Taplytics
  initTaplytics(whitelabelEnvs.taplytics);

  return { storeInstance };
}

export default initializeClientServices;
