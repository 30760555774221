import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import mastercard from 'images/payment/mastercard.svg';
import visa from 'images/payment/visa.svg';
import americanexpress from 'images/payment/americanexpress.svg';
import 'styles/components/purchase/InstallmentsRadio';
import { getCurrencySuffix } from 'utils/currency';

const propTypes = {
  name: PropTypes.string.isRequired,
  checked: PropTypes.bool.isRequired,
  variant: PropTypes.oneOf(['text', 'installments', 'visa', 'amex']),
  checkVariant: PropTypes.oneOf(['border', 'icon']),
  labelText: PropTypes.string,
  installmentsPlan: PropTypes.shape({
    months: PropTypes.number,
    monthlyPayment: PropTypes.number,
  }),
  onClick: PropTypes.func.isRequired,
};

const defaultProps = {
  variant: 'text',
  checkVariant: 'border',
  labelText: '',
  installmentsPlan: {},
};

function InstallmentsRadio({
  name,
  variant,
  checkVariant,
  labelText,
  installmentsPlan,
  checked,
  onClick,
}) {
  const { t } = useTranslation('payment');

  const renderLabel = () => {
    const { months, monthlyPayment } = installmentsPlan;
    let cards = [visa, mastercard];

    if (variant === 'amex') {
      cards = [americanexpress];
    }

    switch (variant) {
      case 'text':
        return (
          <p className="radio-button-label">
            <b>{labelText}</b>
          </p>
        );

      case 'installments':
        return (
          <p className="radio-button-label">
            {`${months} ${t('payments_of')}`}
            <b> {monthlyPayment}</b>
            <small>
              {' '}
              <b>{getCurrencySuffix()}</b>
            </small>
          </p>
        );

      case 'visa':
      case 'amex':
        return (
          <div className="radio-button-label-img">
            {cards.map((card) => (
              <img key={card} src={card} />
            ))}
          </div>
        );

      default:
        return null;
    }
  };

  return (
    <div className="radio-button-container">
      <label className={`installments-radio ${checked ? 'active' : ''}`}>
        <div className="radio-button">
          <div className="radio-button-check">
            <input type="radio" checked={checked} onClick={() => onClick(name)} />
            <span className={checkVariant} />
          </div>

          {renderLabel()}
        </div>
      </label>
    </div>
  );
}

InstallmentsRadio.propTypes = propTypes;
InstallmentsRadio.defaultProps = defaultProps;

export default InstallmentsRadio;
