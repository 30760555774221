import React from 'react';
import PropTypes from 'prop-types';
import { trackEvent } from 'user-analytics';
import { LoaderDots, Text } from '@reservamos/elements';
import './MainButton.scss';
import ArrowRight from './ArrowRight';

const MainButton = ({ onClick, label, isDisabled, isLoading, isFlat }) => {
  const handleOnClick = () => {
    trackEvent('Main Button Click');
    onClick();
  };

  return (
    <>
      <div className="main-button-fixed-space" />
      <button
        className={`main-button ${isFlat ? 'main-button-rounded' : ''} ${
          isLoading ? 'main-button-loading' : ''
        }`}
        onClick={handleOnClick}
        disabled={isDisabled}
      >
        {isLoading ? (
          <LoaderDots />
        ) : (
          <>
            <Text elementType="span" size="L" weight="semibold" color="white" textAlign="center">
              {label}
            </Text>
            {!isDisabled && <ArrowRight />}
          </>
        )}
      </button>
    </>
  );
};

MainButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  isDisabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  isFlat: PropTypes.bool,
};

MainButton.defaultProps = {
  isDisabled: false,
  isLoading: false,
  isFlat: false,
};

export default MainButton;
