import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import LoyaltyWidget from './LoyaltyWidget';

/**
 * Component for rendering a loyalty button or link.
 * Loyalty button could be used as Costapass, Doters or SiemprePlus.
 * @param {Object} param0 - Component props.
 * @param {string} param0.id - The id of the component.
 * @param {boolean} param0.isLink - Param to indicate if the component is a link variant.
 * @param {boolean} param0.visible - Param to indicate if the component is visible.
 * @param {Object} param0.extraConfig - Extra configuration for the loyalty widget such as Costapass.
 * @param {boolean} param0.insideIcon - Param to indicate if the loyalty widget is inside an icon.
 * @param {string} param0.buttonText - The text of the button.
 * @param {boolean} param0.hideIcon - Param to indicate if the loyalty icon should be hidden.
 * @param {boolean} param0.hideButtonLogo - Param to indicate if the loyalty button logo should be hidden.
 * @param {boolean} param0.hideSigninText - Param to indicate if the sign in text should be hidden.
 * @returns {JSX.Element} The rendered component.
 */
const LoyaltyButton = ({
  id,
  isLink,
  visible,
  extraConfig,
  insideIcon,
  buttonText,
  hideIcon,
  hideButtonLogo,
  hideSigninText,
}) => {
  const { t } = useTranslation('loyalty');
  const { env } = useSelector((state) => state.whitelabelConfig);

  if (!env.siemprePlus) return null;

  const { cardType } = env.siemprePlus;

  const contextTypes = {
    siempreplus: 'siempreplus',
    doters: 'doters',
    costapass: 'costapass',
    default: '',
  };
  const context =
    contextTypes[extraConfig?.cardType] || contextTypes[cardType] || contextTypes.default;

  const message = t('invitation_message', { context });
  const cta = buttonText || t('invitation_cta', { context });

  return (
    <LoyaltyWidget
      id={id}
      variant={isLink ? 'link' : 'button'}
      modifierClass={isLink && 'header-app'}
      invitationText={message}
      buttonText={cta}
      visible={visible}
      extraConfig={extraConfig}
      insideIcon={insideIcon}
      hideIcon={hideIcon}
      hideButtonLogo={hideButtonLogo}
      hideSigninText={hideSigninText}
    />
  );
};

LoyaltyButton.propTypes = {
  id: PropTypes.string.isRequired,
  isLink: PropTypes.bool,
  visible: PropTypes.bool,
  extraConfig: PropTypes.object,
  insideIcon: PropTypes.bool,
  buttonText: PropTypes.string,
  hideIcon: PropTypes.bool,
  hideButtonLogo: PropTypes.bool,
  hideSigninText: PropTypes.bool,
};

LoyaltyButton.defaultProps = {
  isLink: false,
  visible: false,
  buttonText: '',
  hideIcon: false,
  hideButtonLogo: false,
  hideSigninText: false,
};

export default LoyaltyButton;
