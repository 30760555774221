import React from 'react';
import PropTypes from 'prop-types';
import TripSummaryDetails from 'components/purchase/TripSummaryDetails';
import 'styles/components/modals/TripSummaryModal';
import { Text } from '@reservamos/elements';
import { useTranslation } from 'react-i18next';
import useWhitelabelTheme from 'hooks/whitelabel/useWhitelabelTheme';

const propTypes = {
  departureTrip: PropTypes.object.isRequired,
  returnTrip: PropTypes.object.isRequired,
  roundTrip: PropTypes.bool.isRequired,
  showModal: PropTypes.func.isRequired,
  hideModal: PropTypes.func.isRequired,
};

const TripSummaryModal = ({ departureTrip, returnTrip, roundTrip, showModal, hideModal }) => {
  const { t } = useTranslation('general');
  const { colors } = useWhitelabelTheme();

  return (
    <div className="modal-content trip-summary" onClick={(e) => e.stopPropagation()}>
      <div className="modal-title">
        <Text color={colors.headerText || 'white'}>{t('trip_details')}</Text>

        <div className="modal-close" onClick={hideModal} />
      </div>

      <TripSummaryDetails
        departureTrip={departureTrip}
        returnTrip={returnTrip}
        roundTrip={roundTrip}
        showModal={showModal}
      />
    </div>
  );
};

TripSummaryModal.propTypes = propTypes;

export default TripSummaryModal;
