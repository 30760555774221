import { required, phone, email } from './formValidations';

const purchaserValidations = {
  purchaserFirstName: required,
  purchaserLastName: required,
  email: [required, email],
  phone: [required, phone],
  phoneCountry: [required],
};

export default purchaserValidations;
