import React from 'react';
import PropTypes from 'prop-types';
import useWhitelabelFeatures from 'hooks/whitelabel/useWhitelabelFeatures';

const propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  time: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      time: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

const StopItem = ({ title, description, time }) => {
  const timeSegments = time.map(({ title, time }, index) => {
    const key = `${time}-${index}`;
    return (
      <p className="route-detail-times" key={key}>
        {title}
        <span>{time}</span>
      </p>
    );
  });

  const features = useWhitelabelFeatures();
  const showTerminal = features.SHOW_TERMINAL_NAME_AT_ITINERARY_ITEM;
  return (
    <div className="route-detail-row stopover">
      <div className="rd-left">{timeSegments}</div>

      <div className="rd-center">
        <i className="ic-stopover" />
      </div>

      <div className="rd-right bus">
        <p>
          <b>{title}</b>
          {showTerminal && <span>{description}</span>}
        </p>
      </div>
    </div>
  );
};

StopItem.propTypes = propTypes;

export default StopItem;
