import React from 'react';
import { useTranslation } from 'react-i18next';
import { CurrentLang } from 'utils/lang';
import { When } from 'react-if';
import { Text, FlatButton, Link, Modal, Spacing } from '@reservamos/elements';
import 'styles/components/overlay/PassengerPolicies';
import { useSelector } from 'react-redux';

const PassengerPolicies = () => {
  const { features, copies } = useSelector((state) => state.whitelabelConfig);
  const { t } = useTranslation('seats');
  const lang = CurrentLang();

  const {
    underagePolicies = [],
    termsAndConditionsUrl,
    noticeOfPrivacy,
    luggagePolicies,
    petsPolicies,
    travelWithUnderagePolicies,
    travelPolicies,
  } = copies.policies[lang];

  const messageContext = termsAndConditionsUrl ? null : 'female';

  if (!features.PASSENGERS_TERMS_AND_CONDITIONS_ENABLED) return null;

  return (
    <>
      <div className="passenger-policies-container">
        <Text size="XS">
          {t('passenger_policy.message', { context: messageContext })}
          <When condition={Boolean(termsAndConditionsUrl)}>
            <Link
              text={t('passenger_policy.terms_and_conditions')}
              href={termsAndConditionsUrl}
              size="XS"
              type="accent"
              weight="regular"
              newTab
            />
          </When>
          <When condition={Boolean(luggagePolicies)}>
            {termsAndConditionsUrl && t('passenger_policy.comma_separator')}
            <Link
              text={luggagePolicies?.label}
              href={luggagePolicies?.url}
              size="XS"
              type="accent"
              weight="regular"
              newTab
            />
          </When>
          <When condition={Boolean(petsPolicies)}>
            {luggagePolicies && t('passenger_policy.comma_separator')}
            <Link
              text={petsPolicies?.label}
              href={petsPolicies?.url}
              size="XS"
              type="accent"
              weight="regular"
              newTab
            />
          </When>
          <When condition={Boolean(travelWithUnderagePolicies)}>
            {petsPolicies && t('passenger_policy.comma_separator')}
            <Link
              text={travelWithUnderagePolicies?.label}
              href={travelWithUnderagePolicies?.url}
              size="XS"
              type="accent"
              weight="regular"
              newTab
            />
          </When>
          {underagePolicies.length > 0
            ? t('passenger_policy.comma_separator')
            : t('passenger_policy.and_separator')}
          <Link
            text={noticeOfPrivacy.label || t('passenger_policy.privacy_notice')}
            href={noticeOfPrivacy.url}
            size="XS"
            type="accent"
            weight="regular"
            newTab
          />
          <When condition={Boolean(travelPolicies)}>
            {t('policies_separator')}
            <Link
              text={travelPolicies?.label || t('passenger_policy.travel_policies')}
              href={travelPolicies?.url}
              size="XS"
              type="accent"
              weight="regular"
              newTab
            />
          </When>
          {underagePolicies.length > 0 && (
            <>
              {t('policies_separator')}
              <Modal
                headerColor="primary"
                title={t('general:policies_title')}
                modalTrigger={
                  <FlatButton size="XS" weight="regular">
                    {t('see_policies')}
                  </FlatButton>
                }
              >
                <Spacing vertical>
                  {Boolean(underagePolicies.length) &&
                    underagePolicies.map((item) => (
                      <Text key={item} size="S">
                        <b>·</b>
                        {` ${item}`}
                      </Text>
                    ))}
                </Spacing>
              </Modal>
            </>
          )}
          {features.DOTERS_ENABLED ? `; ${t('passenger_policy.doters')}` : '.'}
        </Text>
      </div>
    </>
  );
};

export default PassengerPolicies;
