import React from 'react';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import PurchasePricingPassengerTrip from 'components/purchase/PurchasePricingPassengerTrip';
import PurchasePricingTrip from 'components/purchase/PurchasePricingTrip';
import PurchasePricingExtras from 'components/purchase/PurchasePricingExtras';
import PurchasePricingDiscount from 'components/purchase/PurchasePricingDiscount';
import DetailsTitle from 'components/DetailsTitle';
import TransferNotice from 'components/TransferNotice';
import usePricingBeforeCheckout from 'hooks/usePricingBeforeCheckout';
import { useTranslation } from 'react-i18next';
import getPurchaseStepName from '../../../utils/purchase/getPurchaseStepName';
import 'styles/components/purchase/PurchasePricing';
import PurchaseFares from '../PurchaserForm/PurchaseFares';

const propTypes = {
  departs: PropTypes.object.isRequired,
  extras: PropTypes.shape({
    carbon: PropTypes.number,
    total: PropTypes.number.isRequired,
    installmentsFee: PropTypes.number.isRequired,
    insurance: PropTypes.number.isRequired,
    markup: PropTypes.number.isRequired,
  }).isRequired,
  hasMixedTrips: PropTypes.bool.isRequired,
  passengers: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
  passengerCount: PropTypes.number.isRequired,
  titleFooterDescription: PropTypes.string.isRequired,
  titleHeaderDescription: PropTypes.string.isRequired,
  titleTotal: PropTypes.number.isRequired,
  returns: PropTypes.object.isRequired,
  roundTrip: PropTypes.bool.isRequired,
  externalCouponCode: PropTypes.string,
  selectedInstallmentsPlan: PropTypes.string.isRequired,
  monthlySelectedPlan: PropTypes.string.isRequired,
  selectedPaymentOption: PropTypes.shape({
    months: PropTypes.number.isRequired,
  }),
  departsFares: PropTypes.arrayOf(PropTypes.object),
  returnsFares: PropTypes.arrayOf(PropTypes.object),
  isExchange: PropTypes.bool,
};

const PurchasePricing = ({
  departs,
  extras,
  hasMixedTrips,
  passengers,
  passengerCount,
  titleFooterDescription,
  titleHeaderDescription,
  titleTotal,
  returns,
  roundTrip,
  externalCouponCode,
  selectedInstallmentsPlan,
  monthlySelectedPlan,
  selectedPaymentOption,
  departsFares,
  returnsFares,
  isExchange,
}) => {
  const {
    passengersCount: passengerCountBeforeCheckout,
    purchaseTotal: purchaseTotalBeforeCheckout,
    departTripPricing: departTripPricingBeforeCheckout,
    returnTripPricing: returnTripPricingBeforeCheckout,
    displayPricingBeforeCheckout,
  } = usePricingBeforeCheckout({ isRoundTrip: roundTrip });

  const { features, env } = useSelector((state) => state.whitelabelConfig);
  const location = useLocation();
  const { t } = useTranslation('purchase');

  const purchaseStep = getPurchaseStepName(location.pathname);
  if (!['checkout', 'complete'].includes(purchaseStep) && !displayPricingBeforeCheckout)
    return null;

  let detailsTitleHeader = '';

  if (selectedPaymentOption === 'credit_card' && monthlySelectedPlan > 1) {
    detailsTitleHeader = t('label.instalments_title', {
      number: selectedInstallmentsPlan.months,
    });
  } else {
    detailsTitleHeader = t(`label.${titleHeaderDescription}`);
  }

  const exchangeTripTextBrand = {
    gfa: 'your_trip_gfa',
    default: 'your_trip',
  };

  const simpleTextBrand = {
    gfa: 'your_trip_web_gfa',
    default: 'your_trip',
  };

  const simpleTripPriceText = isExchange
    ? exchangeTripTextBrand[env.brand] || exchangeTripTextBrand.default
    : simpleTextBrand[env.brand] || simpleTextBrand.default;
  const departsTotalLabel = roundTrip ? 'your_one_way_trip' : simpleTripPriceText;
  const returnsTotalLabel = 'your_return_trip';

  return (
    <div className="purchase-pricing">
      <DetailsTitle
        title="purchase_details"
        headerDescription={detailsTitleHeader}
        footerDescription={titleFooterDescription}
        total={displayPricingBeforeCheckout ? purchaseTotalBeforeCheckout : titleTotal}
      />

      {hasMixedTrips ? <TransferNotice /> : null}

      {features.TRIP_PRICING_BY_PASSENGER ? (
        <PurchasePricingPassengerTrip
          reservation={departs}
          passengers={passengers}
          passengerCount={passengerCount}
          totalLabel={departsTotalLabel}
          externalCouponCode={externalCouponCode}
        />
      ) : (
        <PurchasePricingTrip
          reservation={departs}
          passengers={passengers}
          passengerCount={passengerCount}
          totalLabel={departsTotalLabel}
          displayPricingBeforeCheckout={displayPricingBeforeCheckout}
          passengersCountBeforeCheckout={passengerCountBeforeCheckout}
          pricingBeforeCheckout={departTripPricingBeforeCheckout}
        />
      )}

      {roundTrip ? (
        <>
          {features.TRIP_PRICING_BY_PASSENGER ? (
            <PurchasePricingPassengerTrip
              reservation={returns}
              passengers={passengers}
              passengerCount={passengerCount}
              totalLabel={returnsTotalLabel}
              externalCouponCode={externalCouponCode}
            />
          ) : (
            <PurchasePricingTrip
              reservation={returns}
              passengers={passengers}
              passengerCount={passengerCount}
              totalLabel={returnsTotalLabel}
              displayPricingBeforeCheckout={displayPricingBeforeCheckout}
              passengersCountBeforeCheckout={passengerCountBeforeCheckout}
              pricingBeforeCheckout={returnTripPricingBeforeCheckout}
            />
          )}
        </>
      ) : null}

      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <PurchasePricingExtras {...extras} />
      <PurchasePricingDiscount />
      {features.SELECTABLE_FARE && (
        <PurchaseFares departsFares={departsFares} returnsFares={returnsFares} />
      )}
    </div>
  );
};

PurchasePricing.propTypes = propTypes;

export default PurchasePricing;
