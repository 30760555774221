import React from 'react';
import PropTypes from 'prop-types';
import { ArrowLabel, Text } from '@reservamos/elements';
import AnimationOverview from './AnimationOverview';

/**
 * Represents a trip schedule component.
 *
 * @param {Object} props - The component props.
 * @param {string} props.departureHour - The departure hour of the trip.
 * @param {string} props.arrivalHour - The arrival hour of the trip.
 * @param {string|array} props.mainContent - The main content of the trip.
 * @param {string} props.mobileSize - The mobile size of the component.
 * @returns {JSX.Element} The rendered trip schedule component.
 */
const TripSchedule = ({ departureHour, arrivalHour, mainContent, mobileSize }) => {
  const mainElement =
    typeof mainContent === 'object' ? (
      <div className="animation-content">
        <AnimationOverview contentTypes={mainContent} />
      </div>
    ) : (
      <Text color="grayLight" size="XS" textAlign="center">
        {mainContent}
      </Text>
    );
  return (
    <>
      <Text mobileSize={mobileSize} size="XL" weight="semibold">
        {departureHour}
      </Text>

      <ArrowLabel>{mainElement}</ArrowLabel>

      <Text mobileSize={mobileSize} size="XL">
        {arrivalHour}
      </Text>
    </>
  );
};

TripSchedule.propTypes = {
  departureHour: PropTypes.string,
  arrivalHour: PropTypes.string,
  mainContent: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  mobileSize: PropTypes.string,
};

TripSchedule.defaultProps = {
  mobileSize: 'M',
};

export default TripSchedule;
