import React from 'react';
import PropTypes from 'prop-types';
import { Text } from '@reservamos/elements';
import { useTranslation } from 'react-i18next';
import useTripDetails from 'hooks/useTripDetails';
import LabelLoading from '../../ui/molecules/LabelLoading';
import TimeLine from '../TimeLine';

/**
 * Component used as part of trip detail modal.
 * @param {Object} props - Object props
 * @param {string} props.tripId - Indicates current trip id.
 * @returns {JSX.Element} A React component that renders the itinerary of current the selected trip.
 */
const ResultItinerary = ({ tripId }) => {
  const { t } = useTranslation(['search', 'purchase']);
  const { tripData, loading } = useTripDetails(tripId);

  /**
   * Sets a fixed default route taking trip information
   * to display when tenant doesn't have path details from api response
   */
  const fixedRoute = [
    {
      type: 'departure',
      time: tripData?.departureTime,
      title: tripData?.originCityName,
      description: `${t('trips:leaves_from')} ${tripData?.originName}`,
    },
    {
      type: 'end',
      time: tripData?.arrivalTime,
      title: tripData?.destinationCityName,
      description: `${t('trips:arrives_in')} ${tripData?.destinationName}`,
    },
  ];

  const currentDetails = {
    route: tripData?.route ?? fixedRoute,
    originDetails: {
      address: tripData?.originAddress,
      coordinates: tripData?.originCoordinates,
    },
    destinationDetails: {
      address: tripData?.destinationAddress,
      coordinates: tripData?.destinationCoordinates,
    },
  };

  if (loading) return <LabelLoading text={t('loading_itinerary')} />;
  if (!currentDetails.route) return <Text>{t('text.do_not_show_itinerary')}</Text>;
  return <TimeLine route={currentDetails} lineDetails={tripData?.lineDetails} />;
};

ResultItinerary.propTypes = {
  tripId: PropTypes.string.isRequired,
  itinerary: PropTypes.shape({
    transportType: PropTypes.string.isRequired,
    fragments: PropTypes.array.isRequired,
  }),
};

export default ResultItinerary;
